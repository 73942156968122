<script>
import {
  MoveOnboarding, eligibilityCriteriaOptions, implementationStatusOptions, benefitsEnrolledEligibleOptions, memberPaymentOptions, channelOptInOptions,
} from '@/scripts/definitions';

import { required } from 'vuelidate/lib/validators';
import helpers from '@/helpers';

const { isOneOf } = helpers;
const indexForThisTab = 9;

export default {
  name: 'TabMove',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      moveOnboardingOptions: MoveOnboarding.map(ic => ({
        value: ic.optValue,
        text: ic.optLabel,
      })),
      eligibilityCriteriaOptions,
      implementationStatusOptions,
      benefitsEnrolledEligibleOptions,
      memberPaymentOptions,
      channelOptInOptions,
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      client: {
        configuration: {
          move: {
            enabled: { required },
            onboarding_type: {
              required,
              oneOf: isOneOf(this.moveOnboardingOptions),
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">Move</span>
      <span v-else class="text-success">Move</span>
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">

        <b-tab title="General" active>
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-move.enabled" label="Is Move enabled:" label-for="input-client-move.enabled">
                <b-form-radio v-model="client.configuration.move.enabled" name="move.enabled-radios"
                  :value="true">Enabled</b-form-radio>
                <b-form-radio v-model="client.configuration.move.enabled" name="move.enabled-radios"
                  :value="false">Disabled</b-form-radio>
              </b-form-group>

              <b-form-group id="group-client-move-support_email" label="Support Email:"
                label-for="input-client-move-support_email" description="">
                <b-form-input id="input-client-move-support_email" v-model.trim="client.configuration.move.support_email"
                  type="text" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-move-implementation-status" description=""
                              label="Implementation Status:" label-for="input-client-move-implementation-status">
                <b-form-select v-model="client.configuration.move.implementation_status"
                    :options="implementationStatusOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-move-eligibility-criteria" description=""
                            label="Eligibility Criteria:" label-for="input-client-move-eligibility-criteria">
                <b-form-select v-model="client.configuration.move.eligibility_criteria"
                  :options="eligibilityCriteriaOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-move-benefits-enrolled-eligible" description=""
                            label="Benefits Enrolled/Eligible:" label-for="input-client-move-benefits-enrolled-eligible">
                <b-form-select v-model="client.configuration.move.benefits_enrolled_eligible"
                  :options="benefitsEnrolledEligibleOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-move-member-payment" description=""
                            label="Member Payment:" label-for="input-client-move-member-payment">
                <b-form-select v-model="client.configuration.move.member_payment"
                  :options="memberPaymentOptions">
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col v-if="$isSudo" cols="12">
                  <label>
                    <b-form-group id="group-client-move.onboarding_type" description="" label="*Onboarding type:"
                      label-for="input-client-move.onboarding_type">
                      <b-form-select id="input-client-move.onboarding_type"
                        v-model="client.configuration.move.onboarding_type" :options="moveOnboardingOptions" />
                    </b-form-group>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-move.navigator_visible"
                      v-model="client.configuration.move.navigator_visible"
                      name="checkbox-client-move.navigator_visible" />
                    Show on navigator even if it is not available
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill
                      title="Unit card will be displayed in the Navigator even if not enabled for the client"
                      variant="primary">
                    <feather type="info"/>
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button :value="false" sync id="checkbox-client-move.institution_configs.email_performances"
                      v-model="client.configuration.move.institution_configs.email_performances"
                      name="checkbox-client-move.institution_configs.email_performances" />
                    Email performances
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button :value="false" sync
                      id="checkbox-client-move.institution_configs.email_professional_after_session"
                      v-model="client.configuration.move.institution_configs.email_professional_after_session"
                      name="checkbox-client-move.institution_configs.email_professional_after_session" />
                    Email professional after session
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button :value="false" sync id="checkbox-client-move.institution_configs.email_suggestions"
                      v-model="client.configuration.move.institution_configs.email_suggestions"
                      name="checkbox-client-move.institution_configs.email_suggestions" />
                    Email suggestions
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-move.toggles.allow_minor_enrollments"
                      v-model="client.configuration.move.toggles.allow_minor_enrollments"
                      name="checkbox-client-move.toggles.allow_minor_enrollments" />
                    Allow enrollments by minors
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.capacity" label="Capacity:" label-for="input-client-move.capacity">
                    <template #label>
                      Enrollment Cap:
                      <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                        title="Use zero for endless capacity.">
                        <feather type="info" />
                      </b-badge>
                    </template>
                    <b-form-input number id="input-client-move.capacity" v-model="client.configuration.move.capacity"
                      type="number" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.salesforce_account_id" label="SalesForce program_id:"
                    label-for="input-client-move.salesforce_account_id" description="">
                    <b-form-input id="input-client-move.salesforce_account_id" maxlength='18'
                      v-model.trim="client.configuration.move.salesforce_account_id" type="text" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.technical_launch_date" label="Technical launch date:"
                    label-for="input-client-move.technical_launch_date" description="">
                    <b-datepicker id="input-client-move.technical_launch_date"
                      v-model="client.configuration.move.technical_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.marketing_launch_date" label="Marketing launch date:"
                    label-for="input-client-move.marketing_launch_date" description="">
                    <b-datepicker id="input-client-move.marketing_launch_date"
                      v-model="client.configuration.move.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.unique_addressable_lives" label="Unique Addressable Lives:"
                    label-for="input-client-move.unique_addressable_lives">
                    <b-form-input number id="input-client-move.unique_addressable_lives"
                      v-model="client.configuration.move.unique_addressable_lives" type="number"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Member app">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-move.academy_enabled"
                  v-model="client.configuration.move.academy_enabled" name="checkbox-client-move.academy_enabled" />
                Academy enabled
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                    title="When enabled it will show academy even if the member is actively enrolled in a program"
                    variant="primary">
                  <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-move.on_call_chat"
                  v-model="client.configuration.move.on_call_chat" name="checkbox-client-move.on_call_chat" />
                On call chat enabled
              </label>
              <b-col cols="6">
                <b-form-group id="group-client-move.on_call_technical_launch_date" description=""
                  label="On-call technical launch date:" label-for="input-client-move.on_call_technical_launch_date">
                  <b-datepicker id="input-client-move.on_call_technical_launch_date"
                    v-model="client.configuration.move.on_call_technical_launch_date"/>
                </b-form-group>
              </b-col>
              <b-badge v-b-tooltip.hover class="float-right" pill
                    title="When enabled it will show On-Call chat available even if the member is actively enrolled in a program"
                    variant="primary">
                  <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-move.show_on_navigator"
                  v-model="client.configuration.move.show_on_navigator" name="checkbox-client-move.show_on_navigator" />
                Show on navigator/hub
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                    title="When enabled the Move card will be available on the Hub tab and on the navigator"
                    variant="primary">
                  <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-move.cbt_program" v-model="client.configuration.move.cbt_program"
                  name="checkbox-client-move.cbt_program" />
                CBT Program
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                    title="This toggle enables the CBT program for members enrolled in Move"
                    variant="primary">
                  <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-move.toggles.show_referrals"
                               v-model="client.configuration.move.toggles.show_referrals"
                               name="checkbox-client-move.toggles.show_referrals"/>
                  Show referrals
              </label>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="High risk">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.high_risk.enabled"
                                   v-model="client.configuration.move.high_risk.enabled"
                                   name="checkbox-client-move.high_risk.enabled" sync/>
                    Is high-risk enabled
                  </label>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
            <b-col cols="12">
                  <b-form-group id="group-client-move.high_risk.marketing_launch_date" description=""
                                label="Marketing launch date:" label-for="input-client-move.high_risk.marketing_launch_date">
                    <b-datepicker id="input-client-move.high_risk.marketing_launch_date"
                                  v-model="client.configuration.move.high_risk.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.high_risk.salesforce_account_id" description=""
                                label="SalesForce program_id:" label-for="input-client-move.high_risk.salesforce_account_id">
                    <b-form-input id="input-client-move.high_risk.salesforce_account_id"
                                  v-model.trim="client.configuration.move.high_risk.salesforce_account_id" required maxlength='18'
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
          </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Marketing">
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-move.marketing_preferences.eligibility_statement_toggle"
                                v-model="client.configuration.move.marketing_preferences.eligibility_statement_toggle"
                                name="checkbox-client-move.marketing_preferences.eligibility_statement_toggle" sync/>
                  Eligibility statement
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                   Eligibility Statement - Disabled if toggle is off on the previous field
                  <b-textarea
                    v-model="client.configuration.move.marketing_preferences.eligibility_statement"
                    :disabled="!client.configuration.move.marketing_preferences.eligibility_statement_toggle"
                    class="mt-4" size="lg"
                    rows="5"/>
                </label>
              </b-col>
              <b-col cols="12">
                <b-form-group id="radiobutton-client-move.marketing_preferences.marketing_access" label="Marketing Access:">
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'very high'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">Very high
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'high'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">High
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'medium'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">Medium
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'low'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'very low'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">Very Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'not standard'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">Not Standard
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.move.marketing_preferences.marketing_access" :value="'no access'"
                                name="radiobutton-client-move.marketing_preferences.marketing_access">No Access
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Channel Opt-in">
                  <b-form-checkbox-group id="checkbox-client-move.marketing_preferences.channel_opt_in"
                                          v-model="client.configuration.move.marketing_preferences.channel_opt_in"
                                          :options="channelOptInOptions" name="checkbox-client-move.marketing_preferences.channel_opt_in"
                                          stacked></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="6">
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.disclaimer_toggle"
                                  v-model="client.configuration.move.marketing_preferences.disclaimer_toggle"
                                  name="checkbox-client-move.marketing_preferences.disclaimer_toggle" sync/>
                    Disclaimer
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    Disclaimer - Disabled if toggle is off on the previous field
                    <b-textarea
                      v-model="client.configuration.move.marketing_preferences.disclaimer"
                      :disabled="!client.configuration.move.marketing_preferences.disclaimer_toggle"
                      class="mt-4" size="lg"
                      rows="5"/>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.allow_thematic_campaigns"
                                  v-model="client.configuration.move.marketing_preferences.allow_thematic_campaigns"
                                  name="checkbox-client-move.marketing_preferences.allow_thematic_campaigns" sync/>
                    Allow thematic campaingns
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.allow_free_language"
                                  v-model="client.configuration.move.marketing_preferences.allow_free_language"
                                  name="checkbox-client-move.marketing_preferences.allow_free_language" sync/>
                    Allow free language
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.requires_content_changes_approval"
                                  v-model="client.configuration.move.marketing_preferences.requires_content_changes_approval"
                                  name="checkbox-client-move.marketing_preferences.requires_content_changes_approval" sync/>
                    Requires content changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.requires_aesthetic_changes_approval"
                                  v-model="client.configuration.move.marketing_preferences.requires_aesthetic_changes_approval"
                                  name="checkbox-client-move.marketing_preferences.requires_aesthetic_changes_approval" sync/>
                    Requires aesthetic changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.sms_allowed"
                                  v-model="client.configuration.move.marketing_preferences.sms_allowed"
                                  name="checkbox-client-move.marketing_preferences.sms_allowed" sync/>
                    SMS allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-move.marketing_preferences.emails_allowed"
                                  v-model="client.configuration.move.marketing_preferences.emails_allowed"
                                  name="checkbox-client-move.marketing_preferences.emails_allowed" sync/>
                    Emails allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.marketing_preferences.member_reference_name"
                                label="Member reference name:"
                                label-for="input-client-move.marketing_preferences.member_reference_name">
                    <b-form-input id="input-client-move.marketing_preferences.member_reference_name"
                                  v-model.trim="client.configuration.move.marketing_preferences.member_reference_name"
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-move.marketing_preferences.date_of_first_communication"
                                label="Date of first communication sent:"
                                label-for="input-client-move.marketing_preferences.date_of_first_communication">
                    <b-form-datepicker id="input-client-move.marketing_preferences.date_of_first_communication"
                                  v-model.trim="client.configuration.move.marketing_preferences.date_of_first_communication"/>
                  </b-form-group>
                </b-col>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
