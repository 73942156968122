<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment/moment';
import { CONTRACT } from '@/scripts/constants';
import axios from 'axios';
import Multiselect from 'vue-multiselect';

const indexForThisTab = 4;

export default {
  components: {
    Multiselect,
  },
  name: 'TabLegal',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      legalDocLanguages: [ 'en', 'en-GB', 'es', 'fr-CA', 'pt' ],
      legalDocs: {
        irb_consent: { title: 'Irb consent' },
        hipaa_consent: { title: 'Hipaa consent' },
        non_discrimination: { title: 'Non discrimination' },
        telehealth_consent: { title: 'Telehealth consent' },
        terms_and_conditions: { title: 'Terms and conditions' },
      },
      externalContracts: {
        loaded: false,
        loading: false,
        contractKeys: [],
        fields: [
          { key: 'externalID', label: 'ExternalID' },
          { key: 'type', label: 'Type' },
          { key: 'launchDate', label: 'Launch Date' },
          { key: 'agreementDate', label: 'Agreement Date' },
          { key: 'updatedAt', label: 'Updated At' },
          { key: 'file', label: 'File' },
        ],
        items: [],
      },
      contracts: {
        loaded: false,
        loading: false,
        fields: [
          { key: 'type', label: 'Type' },
          { key: 'actor', label: 'Actor' },
          { key: 'date', label: 'Date' },
          { key: 'status', label: 'Status' },
          { key: 'file', label: 'File' },
          { key: 'actions', label: 'Actions' },
        ],
        items: [],
        page: 1,
        limit: 10,
        total: 0,
      },
      showHIESettings: false,
    };
  },
  beforeMount() {
    this.showHIESettings = this.$store.getters['Core/showHIESettings'];
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
    legalDocsList() {
      return Object.keys(this.legalDocs);
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
    currentTab() {
      if (this.tabIsActive && this.contracts.loaded === false) {
        this.fetchContracts();
        this.fetchExternalContracts();
      }
    },
  },
  validations() {
    return {
      client: {
        data_access: { required },
      },
    };
  },
  methods: {
    addContractID(id) {
      const tag = {
        name: id,
        code: id,
      };
      this.externalContracts.contractKeys.push(tag);
      return tag;
    },
    linkClientToExternalContract() {
      const { clientID } = this.$route.params;
      this.externalContracts.loading = true;

      axios.post(`v1/external/contracts/${clientID}`,
        {
          type: 'ironClad',
          contract_keys: this.externalContracts.contractKeys.map(contractId => contractId.code),
        })
        .then(() => {
          this.fetchExternalContracts();
        })
        .catch(err => {
          this.$noty.error('An error occurred when tried link client to contracts');
          console.error('failed to link contracts to a client:', err);
        }).finally(() => {
          this.externalContracts.loading = false;
        });
    },
    fetchExternalContracts() {
      const { clientID } = this.$route.params;
      this.externalContracts.contractKeys = [];

      this.externalContracts.loading = true;
      return axios.get(`v1/external/contracts/${clientID}`)
        .then(response => {
          response.data.data.forEach(contract => {
            this.externalContracts.contractKeys.push({ name: contract.external_key, code: contract.external_key });
            this.fetchExternalContract(contract.external_key);
          });
        })
        .catch(err => {
          this.$noty.error('An error occurred while fetching contracts');
          console.error('failed to fetch contracts:', err);
        })
        .finally(() => {
          this.externalContracts.loading = false;
          this.externalContracts.loaded = true;
        });
    },
    fetchExternalContract(externalKey) {
      this.externalContracts.items = [];

      axios.get(`v1/external/contract/${externalKey}`)
        .then(externalContract => {
          this.externalContracts.items.push(externalContract.data);
        })
        .catch(err => {
          this.$noty.error(`An error occurred when tried fetch contract: ${externalKey}`);
          console.error('failed to fetch contract:', err);
        });
    },
    downloadExternalContract(contractID) {
      this.externalContracts.loading = true;

      return axios.get(`v1/external/contract/${contractID}/download`, {
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.downloadFile(response.data, contractID);
        })
        .catch(err => {
          this.$noty.error('An error occurred while downloading contract');
          console.error('failed to download contract: ', err);
        })
        .finally(() => {
          this.externalContracts.loading = false;
        });
    },
    fetchContracts() {
      const { clientID } = this.$route.params;
      const params = {
        offset: (this.contracts.page - 1) * this.contracts.limit,
        limit: this.contracts.limit,
      };

      this.contracts.loading = true;
      return axios.get(`v1/clients/${clientID}/contracts`, { params })
        .then(response => {
          this.contracts.total = response.data.total;
          this.contracts.items = response.data.data;
        })
        .catch(err => {
          this.$noty.error('An error occurred while fetching contracts');
          console.error('failed to fetch contracts:', err);
        })
        .finally(() => {
          this.contracts.loading = false;
          this.contracts.loaded = true;
        });
    },
    downloadContract(contractID, fileName) {
      const { clientID } = this.$route.params;

      this.contracts.loading = true;

      return axios.get(`v1/clients/${clientID}/contracts/${contractID}/download`, {
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.downloadFile(response.data, fileName);
        })
        .catch(err => {
          this.$noty.error('An error occurred while downloading contract');
          console.error('failed to download contract: ', err);
        })
        .finally(() => {
          this.contracts.loading = false;
        });
    },
    downloadFile(bufferArray, name) {
      const fileURL = window.URL.createObjectURL(new Blob([ bufferArray ], { type: 'application/pdf' }));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', name);
      document.body.appendChild(fileLink);

      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
    },
    onChangePage(page) {
      this.contracts.page = page;
      this.fetchContracts();
    },
    formatDatetime(value) {
      if (!value) {
        return null;
      }
      return moment(value).utc().format('YYYY-MM-DD');
    },
    formatStatus(status) {
      return CONTRACT.status[status] || status;
    },
    formatType(type) {
      return CONTRACT.type[type] || type;
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      Legal
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">
        <b-tab title="General">
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-data_access" label="*Data access:"
                            label-for="input-client-data_access"
                            description="Client data that can be accessed outside US (Standard).">
                <b-form-radio v-model="client.data_access"
                              name="data_access-radios"
                              :disabled="editing"
                              value="normal">Normal
                </b-form-radio>
                <b-form-radio v-model="client.data_access"
                              name="data_access-radios"
                              :disabled="editing"
                              value="strict">Strict - Zero Tolerance
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-row>
                <b-col v-if="showHIESettings" cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-toggles.enable_health_integration_exchange"
                                   v-model="client.configuration.toggles.enable_health_integration_exchange"
                                   name="checkbox-client-toggles.enable_health_integration_exchange"/>
                    Enable health integration exchange - Sword 360
                  </label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col class="col-12 d-flex align-items-center">
              <h5 class="my-3"><b>Contracts IronClad</b></h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-4 d-flex align-items-center">
              <multiselect
                v-model="externalContracts.contractKeys"
                tag-placeholder="Add contract ID"
                placeholder="Contract IDs"
                :options="externalContracts.contractKeys"
                track-by="code"
                label="name"
                :multiple="true"
                :taggable="true"
                @tag="addContractID($event)" />
            </b-col>
            <b-button variant="primary"
                      :disabled="externalContracts.loading"
                      @click="linkClientToExternalContract">
              <feather type="refresh-ccw"></feather>
              Link Contracts
            </b-button>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-table :fields="externalContracts.fields" :items="externalContracts.items" :busy="externalContracts.loading"
                        outlined striped small empty-text="No IronClad contracts">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(externalID)="data">
                  {{ data.item.ironcladId }}
                </template>
                <template #cell(updatedAt)="data">
                  {{ data.item.lastUpdated }}
                </template>
                <template #cell(launchDate)="data">
                  {{ data.item.properties.launchDate.value }}
                </template>
                <template #cell(agreementDate)="data">
                  {{ data.item.properties.agreementDate.value }}
                </template>
                <template #cell(file)="data">
                  <b-button class="contract-download" variant="link" @click="downloadExternalContract(data.item.id)">
                    <feather type="download"></feather>
                    {{ data.item.name }}
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col class="col-12 d-flex align-items-center">
              <h5 class="my-3"><b>Contracts</b></h5>
              <b-button variant="primary"
                        class="ml-auto"
                        :disabled="contracts.loading"
                        :to="`/client/${client.id}/contract/`">
                <feather type="plus"></feather>
                Add Contract
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-table :fields="contracts.fields" :items="contracts.items" :busy="contracts.loading"
                       outlined striped small empty-text="No contracts">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template #cell(type)="data">
                  {{ formatType(data.item.type) }}
                </template>
                <template #cell(actor)="data">
                  <ul class="list-unstyled">
                    <li><b>Created_by</b>: {{data.item.created_by}}</li>
                    <li><b>Updated_by</b>: {{data.item.updated_by}}</li>
                  </ul>
                </template>
                <template #cell(date)="data">
                  <ul class="list-unstyled">
                    <li><b>Start</b>: {{data.item.start_date}}</li>
                    <li><b>End</b>: {{data.item.end_date}}</li>
                  </ul>
                </template>
                <template #cell(status)="data">
                  {{ formatStatus(data.item.status) }}
                </template>
                <template #cell(file)="data">
                  <b-button class="contract-download" variant="link" @click="downloadContract(data.item.id, data.item.file_name)">
                    <feather type="download"></feather>
                    {{ data.item.file_name }}
                  </b-button>
                </template>
                <template #cell(actions)="data">
                  <router-link :to="`/client/${data.item.client_id}/contract/${data.item.id}`">
                    <feather type="edit"></feather>
                  </router-link>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="d-flex justify-content-center">
              <b-pagination
                v-if="contracts.total > 0"
                :disabled="contracts.loading"
                v-model="contracts.page"
                :total-rows="contracts.total"
                :per-page="contracts.limit"
                @change="onChangePage"
                size="md">
              </b-pagination>
              <p v-else>No contracts added</p>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-for="lang in legalDocLanguages" :key="lang" :title="`${lang} docs`">
          <b-row>
            <b-col cols="6" v-for="doc in legalDocsList" :key="doc">
              <b-form-group :id="`group-client-legal_links.${lang}.${doc}`" :label="`${legalDocs[doc].title}:`"
                            :label-for="`input-client-legal_links.${lang}.${doc}`" description="">
                <b-form-input :id="`input-client-legal_links.${lang}.${doc}`"
                              v-model.trim="client.configuration.legal_links[lang][doc]"
                              type="text" :placeholder="doc"
                              required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card-text>
  </b-tab>
</template>

<style scoped>
.contract-download {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
