<script>
import * as R from 'ramda';
import axios from 'axios';
import Vue from 'vue';
import {
  UNIT_BLOOM, UNIT_MIND, UNIT_MOVE, UNIT_SWORD,
} from '@/scripts/constants';
import { capitalize } from '@/core/filters';

export default {
  name: 'TabStatus',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    activeTab: String,
  },
  beforeMount() {
    this.fetchTherapists();
    this.fetchEligibilitySettings();
    this.fetchIntegrations();
  },
  data() {
    return {
      processStatus: {
        therapists: { loading: false },
      },
      eligibilitySettingsResponse: null,
      integrationsResponse: null,
      therapists: {
        list: [],
        dpt: {
          count: { intake: 0, treatment: 0 },
          list: { intake: [], treatment: [] },
        },
        bloom: {
          count: { intake: 0, treatment: 0 },
          list: { intake: [], treatment: [] },
        },
        mind: {
          count: { intake: 0, treatment: 0 },
          list: { intake: [], treatment: [] },
        },
        move: {
          count: { intake: 0, treatment: 0 },
          list: { intake: [], treatment: [] },
        },
      },
      professionalListModal: {
        title: 'Therapists',
        fields: [
          { key: 'id', label: '#', thClass: 'col-md-2' },
          { key: 'name', label: 'Name', thClass: 'col-md-3' },
          { key: 'email', label: 'Email', thClass: 'col-md-5' },
          { key: 'status', label: 'Status', thClass: 'col-md-2' },
        ],
        list: [],
      },
      professionalsAvailabilityConfigs: {
        data: {
          selection_type: 'manual',
          tags: [],
          extra_filter_type: 'none',
          filtered_professionals_ids: [],
          filtered_professionals: [],
        },
      },
    };
  },
  computed: {
    integrations() {
      if (!R.path([ 'ecosystems' ])(this.integrationsResponse)) {
        return [ 'None' ];
      }
      const integrations = [];

      this.integrationsResponse.ecosystems.forEach(ecosystem => {
        if (!ecosystem.enabled) {
          return;
        }
        integrations.push(ecosystem.ecosystem_id);
      });

      if (integrations.length === 0) {
        return [ 'None' ];
      }

      return integrations;
    },
    eligibilitySettings() {
      if (!R.path([ 'configs', 'data' ])(this.eligibilitySettingsResponse)) {
        return [ 'None' ];
      }
      const eligibilitySettings = [];

      if (this.eligibilitySettingsResponse.configs.data.eligibility_file) {
        eligibilitySettings.push('eligibility_file');
      }
      if (this.eligibilitySettingsResponse.configs.data.availity) {
        eligibilitySettings.push('availity');
      }
      if (this.eligibilitySettingsResponse.configs.data.waystar) {
        eligibilitySettings.push('waystar');
      }
      if (this.eligibilitySettingsResponse.configs.data.change_healthcare) {
        eligibilitySettings.push('change_healthcare');
      }
      if (this.eligibilitySettingsResponse.configs.data.sharecare) {
        eligibilitySettings.push('sharecare');
      }
      if (this.eligibilitySettingsResponse.configs.data.solera) {
        eligibilitySettings.push('solera');
      }
      if (this.eligibilitySettingsResponse.configs.data.whitelist_sword_emails) {
        eligibilitySettings.push('whitelist_sword_emails');
      }

      if (eligibilitySettings.length === 0) {
        return [ 'None' ];
      }

      return eligibilitySettings;
    },
    isFingerprintActive() {
      const fields = R.path([ 'configs', 'data', 'eligibility_file_unique_id_fields' ])(this.eligibilitySettingsResponse);
      return fields && fields.length > 0;
    },
    productMix() {
      const productMix = [];
      if (this.client.configuration.dpt.enabled) {
        productMix.push('Thrive');
      }
      if (this.client.configuration.bloom.enabled) {
        productMix.push('Bloom');
      }
      if (this.client.configuration.move.enabled) {
        productMix.push('Move');
      }
      if (this.client.configuration.mind.enabled) {
        productMix.push('Mind');
      }
      if (this.client.configuration.no_program.on_call_chat) {
        productMix.push('On Call');
      }
      if (this.client.configuration.no_program.academy_enabled) {
        productMix.push('Academy');
      }

      return productMix.join(', ');
    },
    onboardingPageURL() {
      let onboardingUrl = this.$store.getters['Core/getEnvironmentConfigs'].onboarding_url;
      if (onboardingUrl === undefined) {
        onboardingUrl = '';
      }
      onboardingUrl = onboardingUrl.replace(/\/$/, '');
      return `${onboardingUrl}/c/${this.client.reference}`;
    },
  },
  watch: {
    'client.institution_id': {
      handler() {
        this.fetchTherapists();
        this.fetchEligibilitySettings();
        this.fetchIntegrations();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleClientActive() {
      let message = 'Are you sure you want to enable this client ?';
      if (this.client.active) {
        message = 'Are you sure you want to disable this client ?';
      }

      return this.$bvModal.msgBoxConfirm(message, { title: 'Confirmation' })
        .then(value => {
          if (!value) {
            return;
          }
          axios.patch(`v1/clients/${this.client.id}/active`, { active: !this.client.active })
            .then(() => {
              this.client.active = !this.client.active;
              Vue.prototype.$noty.success(this.client.active ? 'Client is now enabled' : 'Client is now disabled');
            }).catch(() => {
              Vue.prototype.$noty.error(this.client.active ? 'Error deactivating client' : 'Error activating client');
            });
        })
        .catch(() => {
          Vue.prototype.$noty.error('Cannot open confirmation dialog');
        });
    },
    fetchEligibilitySettings() {
      if (!this.client.id) {
        return;
      }
      axios.get(`v1/clients/${this.client.id}/eligibility/settings`)
        .then(response => {
          this.eligibilitySettingsResponse = response.data;
        });
    },
    fetchIntegrations() {
      if (!this.client.id) {
        return;
      }
      axios.get(`v1/clients/${this.client.id}/integrations`)
        .then(response => {
          this.integrationsResponse = response.data;
        });
    },
    syncToNetSuite() {
      axios.post(`v1/netsuite/clients/${this.client.id}/sync`)
        .then(() => {
          Vue.prototype.$noty.success('Sync to NetSuite success');
        })
        .catch(error => {
          Vue.prototype.$noty.error('Sync to NetSuite error');
          console.error(error);
        });
    },
    async fetchTherapists() {
      if (!this.client.institution_id || this.therapists.list.length > 0 || this.processStatus.therapists.loading) {
        return;
      }

      this.processStatus.therapists.loading = true;
      await this.fetchProfessioanalAvailabilityConfiguration();

      Promise.allSettled(
        [
          this.getTherapistList(this.client.institution_id, UNIT_SWORD, 'treatment'),
          this.getTherapistList(this.client.institution_id, UNIT_SWORD, 'intake'),
          this.getTherapistList(this.client.institution_id, UNIT_BLOOM, 'treatment'),
          this.getTherapistList(this.client.institution_id, UNIT_BLOOM, 'intake'),
          this.getTherapistList(this.client.institution_id, UNIT_MOVE, 'treatment'),
          this.getTherapistList(this.client.institution_id, UNIT_MOVE, 'intake'),
          this.getTherapistList(this.client.institution_id, UNIT_MIND, 'treatment'),
          this.getTherapistList(this.client.institution_id, UNIT_MIND, 'intake'),
        ],
      ).then(results => {
        [
          this.therapists.dpt.list.treatment,
          this.therapists.dpt.list.intake,
          this.therapists.bloom.list.treatment,
          this.therapists.bloom.list.intake,
          this.therapists.move.list.treatment,
          this.therapists.move.list.intake,
          this.therapists.mind.list.treatment,
          this.therapists.mind.list.intake,
        ] = results.map(result => (result.status === 'fulfilled' ? result.value : []));

        this.therapists.dpt.count.treatment = this.therapists.dpt.list.treatment.length;
        this.therapists.dpt.count.intake = this.therapists.dpt.list.intake.length;

        this.therapists.bloom.count.treatment = this.therapists.bloom.list.treatment.length;
        this.therapists.bloom.count.intake = this.therapists.bloom.list.intake.length;

        this.therapists.move.count.treatment = this.therapists.move.list.treatment.length;
        this.therapists.move.count.intake = this.therapists.move.list.intake.length;

        this.therapists.mind.count.treatment = this.therapists.mind.list.treatment.length;
        this.therapists.mind.count.intake = this.therapists.mind.list.intake.length;
      }).catch(e => {
        Vue.prototype.$noty.error('Cannot fetch therapists', e);
      }).finally(() => {
        this.processStatus.therapists.loading = false;
      });
    },
    async fetchProfessioanalAvailabilityConfiguration() {
      return axios.get(`v1/clients/${this.client.id}/professionals-configuration`)
        .then(response => {
          this.professionalsAvailabilityConfigs.data = response.data.payload;
        })
        .catch(e => {
          if (e.response.status !== 404) {
            this.$noty.error('Error loading client configuration');
            console.error(e);
          }
        });
    },
    getTherapistList(institutionId, unit, flow) {
      const params = {
        institution_id: institutionId,
        ecosystem_unit: unit,
        intake_flow: flow === 'intake' ? 1 : 0,
        treatment_flow: flow === 'treatment' ? 1 : 0,
        provides_remote_therapy: 1,
        selection_type: this.professionalsAvailabilityConfigs.data.selection_type,
        tags: this.professionalsAvailabilityConfigs.data.tags?.join(',') || null,
        extra_filter_type: this.professionalsAvailabilityConfigs.data.extra_filter_type,
        filtered_professionals_ids: this.professionalsAvailabilityConfigs.data.filtered_professionals_ids?.join(',') || null,
        enable_alert: false,
      };

      if (flow === 'intake') {
        delete params.provides_remote_therapy;
      }

      return axios.get(`/v1/clients/${this.client.id}/professionals`, { params })
        .then(response => response.data?.professionals || [])
        .catch(e => {
          Vue.prototype.$noty.error('Cannot fetch treatment therapists', e);
          return [];
        });
    },
    professionalIcons(professional) {
      const icons = {
        provides_remote_therapy: {
          label: 'Provides remote therapy (institution configs)',
          color: 'text-danger',
          icon: 'x-circle',
        },
        sword_email_address: {
          label: 'Valid email',
          color: 'text-danger',
          icon: 'x-circle',
        },
      };

      if (professional.onboarding_information.provides_remote_therapy) {
        icons.provides_remote_therapy.icon = 'check-circle';
        icons.provides_remote_therapy.color = 'text-success';
      }

      const emailRegex = new RegExp('^[a-z0-9_.]+@(?:swordhealth.com|swordcare.com)');
      if (emailRegex.test(professional.email)) {
        icons.sword_email_address.icon = 'check-circle';
        icons.sword_email_address.color = 'text-success';
      }

      return icons;
    },
    showProfessionalsList(unit, type) {
      this.professionalListModal.title = `${unit.toUpperCase()} - ${capitalize(type)} Therapists Available`;
      this.professionalListModal.list = this.therapists[unit].list[type];
      this.$refs['modal-professional-list'].show();
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1" :active="activeTab === 'TabStatus'">
    <template #title>Status</template>
    <b-card-text>
      <b-row>
        <b-col cols="4">
          <b-list-group>
            <b-list-group-item variant="dark">Therapists Available</b-list-group-item>
            <b-list-group-item button @click="showProfessionalsList('dpt', 'intake')" class="d-flex justify-content-between align-items-center">
              • Thrive Intake
              <b-badge variant="primary" pill>{{ therapists.dpt.count.intake }}</b-badge>
            </b-list-group-item>
            <b-list-group-item button @click="showProfessionalsList('dpt', 'treatment')" class="d-flex justify-content-between align-items-center">
              • Thrive Treatment
              <b-badge variant="primary" pill>{{ therapists.dpt.count.treatment }}</b-badge>
            </b-list-group-item>

            <b-list-group-item button @click="showProfessionalsList('bloom', 'intake')" class="d-flex justify-content-between align-items-center">
              • Bloom Intake
              <b-badge variant="primary" pill>{{ therapists.bloom.count.intake }}</b-badge>
            </b-list-group-item>
            <b-list-group-item button @click="showProfessionalsList('bloom', 'treatment')" class="d-flex justify-content-between align-items-center">
              • Bloom Treatment
              <b-badge variant="primary" pill>{{ therapists.bloom.count.treatment }}</b-badge>
            </b-list-group-item>

            <b-list-group-item button @click="showProfessionalsList('move', 'intake')" class="d-flex justify-content-between align-items-center">
              • Move Intake
              <b-badge variant="primary" pill>{{ therapists.move.count.intake }}</b-badge>
            </b-list-group-item>
            <b-list-group-item button @click="showProfessionalsList('move', 'treatment')" class="d-flex justify-content-between align-items-center">
              • Move Treatment
              <b-badge variant="primary" pill>{{ therapists.move.count.treatment }}</b-badge>
            </b-list-group-item>

            <template v-if="$isSudo">
              <b-list-group-item button @click="showProfessionalsList('mind', 'intake')" class="d-flex justify-content-between align-items-center">
                • Mind Intake
                <b-badge variant="primary" pill>{{ therapists.mind.count.intake }}</b-badge>
              </b-list-group-item>
              <b-list-group-item button @click="showProfessionalsList('mind', 'treatment')" class="d-flex justify-content-between align-items-center">
                • Mind Treatment
                <b-badge variant="primary" pill>{{ therapists.mind.count.treatment }}</b-badge>
              </b-list-group-item>
            </template>

          </b-list-group>
        </b-col>

        <b-col cols="8" v-if="editing">
          <b-card>
            <ul class="list-unstyled">
              <li><b>Product mix</b>: {{ productMix }}</li>
              <li><b>Onboarding page</b>: <a :href="onboardingPageURL" target="_blank">{{onboardingPageURL}}</a></li>
              <li v-if="eligibilitySettingsResponse">
                <b>Eligibility Verification Method</b>:
                <template v-for="eligibilityType in eligibilitySettings">
                  <b-badge class="mr-2" :key="eligibilityType">{{ eligibilityType }}</b-badge>
                </template>
              </li>
              <li v-if="integrationsResponse"><b>Integrations</b>:
                <template v-for="integration in integrations">
                  <b-badge class="mr-2" :key="integration">{{ integration }}</b-badge>
                </template>
              </li>
              <li v-if="eligibilitySettingsResponse"><b>Fingerprint configured</b>:
                <b-badge v-if="isFingerprintActive" variant="success">Yes</b-badge>
                <b-badge v-else variant="danger">No</b-badge>
              </li>
            </ul>
          </b-card>

          <b-button-group vertical v-if="$isSudo" class="mt-5">
            <b-button variant="danger" @click="toggleClientActive" v-if="client.active">Deactivate client</b-button>
            <b-button variant="success" @click="toggleClientActive" v-else>Activate client</b-button>
            <b-button variant="info" @click="syncToNetSuite">Sync to NetSuite</b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-modal ref='modal-professional-list' size="xl" :title='professionalListModal.title'>
        <b-table :fields="professionalListModal.fields" :items="professionalListModal.list"
                 outlined striped small empty-text="No therapists">
          <template #cell(id)="data">
            <router-link :to="{ name: 'ProfessionalEditDeprecated', params: { id: data.item.id } }">{{ data.item.id }}</router-link>
          </template>
          <template #cell(name)="data">
            {{ data.item.firstname }} {{ data.item.lastname }}
          </template>
          <template #cell(status)="data">
            <feather v-for='(professionalIcon, iconKey) in professionalIcons(data.item)' :key='iconKey'
                     v-b-popover.hover.top='professionalIcon.label' :type='professionalIcon.icon'
                     :class='`mr-lg-3 ${professionalIcon.color}`'/>
          </template>
        </b-table>
      </b-modal>

    </b-card-text>
  </b-tab>
</template>
