<script>
import { required } from 'vuelidate/lib/validators';

import { implementationStatusOptions } from '@/scripts/definitions';

const indexForThisTab = 12;

export default {
  name: 'TabNoProgram',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      implementationStatusOptions,
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      client: {
        configuration: {
          move: {
            enabled: { required },
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">No-program</span>
      <span v-else class="text-success">No-program</span>
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-no_program.academy_enabled"
                               v-model="client.configuration.no_program.academy_enabled"
                               name="checkbox-client-no_program.academy_enabled"/>
                Academy enabled
              </label>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-no_program.on_call_chat"
                               v-model="client.configuration.no_program.on_call_chat"
                               name="checkbox-client-no_program.on_call_chat"/>
                On call chat enabled
              </label>
              <b-form-group id="group-client-no_program.on_call_technical_launch_date" description=""
                label="On-call technical launch date:" label-for="input-client-no_program.on_call_technical_launch_date">
                <b-datepicker id="input-client-no_program.on_call_technical_launch_date"
                  v-model="client.configuration.no_program.on_call_technical_launch_date"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group id="group-client-no_program.salesforce_account_id"
                            label-for="input-client-no_program.salesforce_account_id" description="">
                <template #label>
                  SalesForce program_id:
                  <b-badge variant="primary" pill class="float-right"
                           v-b-tooltip.hover
                           title="Only used for On Call programs - there is no ID for Academy">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-no_program.salesforce_account_id"
                              v-model.trim="client.configuration.no_program.salesforce_account_id" type="text"
                              required></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-dpt-implementation-status" description=""
                              label="Implementation Status:" label-for="input-client-dpt-implementation-status">
                <b-form-select v-model="client.configuration.dpt.implementation_status"
                    :options="implementationStatusOptions">
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-no_program.cbt_program"
                               v-model="client.configuration.no_program.cbt_program"
                               name="checkbox-client-no_program.cbt_program"/>
                CBT Program
              </label>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-card-text>
  </b-tab>
</template>
