<script>
import { definitions } from '@sword-health/ui-core';
import {
  dateFormatOptions,
  professionalsLanguages,
  timeFormatOptions,
  timezoneOptions as appointmentsTimezoneOptions,
} from '@/scripts/definitions';
import helpers from '@/helpers';
import timezonesList from '@/scripts/tools/timezones';

const { isOneOf, containsAnotherFieldValue } = helpers;

const countryOptions = definitions.swordCountriesList.map(country => ({
  text: country.name,
  value: country.code.toLowerCase(),
}));

export default {
  name: 'TabLocalization',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
  },
  data() {
    return {
      countryOptions,
      dateFormatOptions,
      timeFormatOptions,
      appointmentsTimezoneOptions,
      professionalsLanguages,
    };
  },
  computed: {
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {
      client: {
        configuration: {
          country: { oneOf: isOneOf(countryOptions) },
          language: { oneOf: isOneOf(professionalsLanguages) },
          available_languages: { haveDefaultLanguage: containsAnotherFieldValue('language') },
          date_format: { oneOf: isOneOf(dateFormatOptions) },
          time_format: { oneOf: isOneOf(timeFormatOptions) },
          timezone: { oneOf: isOneOf(appointmentsTimezoneOptions) },
          institution_timezone: { oneOf: isOneOf(this.timezoneConfigs()) },
        },
      },
    };
  },
  methods: {
    timezoneConfigs() {
      const timezones = [];

      timezones
        .push(...timezonesList
          .filter(k => k.optCountry.some(element => element === this.client.configuration.country.toUpperCase()))
          .map(t => ({
            text: t.optLabel,
            value: t.optValue,
          })));

      return timezones;
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      Localization
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-row>

        <b-col cols="4">
          <b-form-group label="Country:">
            <b-form-select v-model="client.configuration.country"
              :options="countryOptions">
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Default Language:">
            <b-form-select v-model="client.configuration.language"
                           :options="professionalsLanguages">
            </b-form-select>
            <template #label>
              Default Language:
              <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover title="The language the Onboarding page will default to">
                <feather type="info"/>
              </b-badge>
            </template>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Available Languages For Video Call:">
            <b-form-checkbox-group v-model="client.configuration.available_languages"
                                   :options="professionalsLanguages" stacked></b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Timezone:">
            <b-form-select v-model="client.configuration.institution_timezone"
                           :options="timezoneConfigs()">
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Date Format:">
            <b-form-select v-model="client.configuration.date_format"
                           :options="dateFormatOptions">
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Time Format:">
            <b-form-select v-model="client.configuration.time_format"
                           :options="timeFormatOptions">
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group label="Appointments Timezone:">
            <b-form-select v-model="client.configuration.timezone"
                           :options="appointmentsTimezoneOptions">
            </b-form-select>
            <template #label>
              Appointments Timezone:
              <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                        title="Timezone for Appointment call slots to will be displayed to the member">
                <feather type="info"/>
              </b-badge>
            </template>
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <template #label>
              Global Client:
              <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                       title="This option can be enabled only when DPT Go is the sole active unit.">
                <feather type="info"/>
              </b-badge>
            </template>
            <toggle-button sync id="checkbox-client-global_client"
                           v-model="client.configuration.toggles.global_client"
                           name="checkbox-client-global_client"/>
          </b-form-group>
        </b-col>

      </b-row>

    </b-card-text>
  </b-tab>
</template>
