<template>
  <b-modal
    v-if="isVisible && vendor"
    size="lg"
    button-size="sm"
    :visible="isVisible"
    centered
    @hidden="close"
    @cancel="close">
    <template #modal-title>
      <h6>Edit {{ client.name }} - {{ vendor.name }} configs</h6>
    </template>

    <div
      v-if="loadingVendorServices"
      class="w-100 h-100 d-flex align-items-center justify-content-center">
      <loading-content />
    </div>

    <b-row class="mt-3" v-if="vendor && !loadingVendorServices">
      <b-col>
        <h6>{{ vendor.name }} services available for {{ client.name }} members</h6>

        <b-form-group
          v-if="Array.isArray(vendorServices) && vendorServices.length"
          v-slot="{ ariaDescribedby }"
          :state="isValid('enabledServices')"
          :invalid-feedback="handleError('enabledServices')"
          label-sr-only>
          <b-form-checkbox
            v-for="(vendorService, index) in vendorServices"
            :key="`service-${index}`"
            :id="`service-${index}`"
            :ref="`serviceCheckbox-${index}`"
            v-model="enabledServices"
            :value="vendorService"
            name="vendor-service"
            size="lg"
            :aria-describedBy="ariaDescribedby"
            switch
            class="my-1">
            <div class="d-flex align-items-center">
              <div
                v-b-tooltip.hover
                :style="{ fontSize: '1rem !important' }"
                class="cursor-pointer"
                :title="getServiceTooltip(vendorService.service.id)">
                {{ vendorService.service.name }}
              </div>

              <b-button
                class="ml-2"
                variant="link"
                size="sm"
                :disabled="!isChecked(vendorService.id)"
                @click.prevent.stop="editVendorService(vendorService)">
                <feather type="edit"></feather>
                Edit
              </b-button>
            </div>
          </b-form-checkbox>
        </b-form-group>

        <div v-else>
          No vendor services added, add one on the Care ecosystem > Ecosystem vendors page
        </div>
      </b-col>
    </b-row>

    <template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>

      <b-button
        variant="primary"
        @click="save"
        :disabled="!isValidForm">
        Save
      </b-button>
    </template>

    <edit-vendor-service-modal
      :client="client"
      :vendor="vendor"
      :vendor-service="editingVendorService"
      @updated-service="updatedService"
      @close="closeEditVendorServiceModal" />
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import LoadingContent from '@/components/LoadingContent.vue';
import axios from 'axios';
import EditVendorServiceModal from './EditVendorServiceModal.vue';

export default {
  name: 'EditVendorModal',
  components: {
    EditVendorServiceModal,
    LoadingContent,
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      enabledServices: { required },
    };
  },
  data() {
    return {
      errorMessages: {
        required: 'This field is required',
      },
      enabledServices: [],
      vendorServices: [],
      services: [],
      loadingVendorServices: false,
      editingVendorService: null,
    };
  },
  props: {
    client: Object,
    vendor: Object,
  },
  computed: {
    isValidForm() {
      return !this.$v.enabledServices.$invalid;
    },
    isVisible() {
      return !!this.vendor;
    },
  },
  watch: {
    vendor(value) {
      if (value) {
        this.fetchServicesByVendor(value);
      }
    },
  },
  methods: {
    getServiceTooltip(id) {
      return this.services.find(service => service.id === id)?.description;
    },
    validateIsArrayAndLenght(array) {
      return Array.isArray(array) && array.length;
    },
    isChecked(id) {
      return this.validateIsArrayAndLenght(this.enabledServices)
        && this.enabledServices.some(service => service.id === id);
    },
    save() {
      if (this.isValidForm) {
        const payload = {
          vendor_services: this.enabledServices.map(enabledService => ({
            id: enabledService.id,
            description_en: enabledService.description_en,
            description_pt: enabledService.description_pt,
            description_es: enabledService.description_es,
            description_fr: enabledService.description_fr,
          })),
        };

        axios.put(`/v1/care-ecosystem/institutions/${this.client.institution_id}/vendor-services`, payload)
          .then(() => {
            this.$noty.success('Vendor updated successfully');

            this.close();
          }).catch(err => {
            this.$noty.error(`'Failed to update the vendor: ${err}`);
          });
      } else {
        this.$noty.error('Please fill all required fields');
      }
    },
    fetchServicesByVendor(vendor) {
      this.loadingVendorServices = true;
      this.enabledServices = [];
      this.vendorServices = [];

      const params = {
        fetch_vendor_services: true,
        fetch_services: true,
      };

      axios.get(`/v1/care-ecosystem/vendors/${vendor.id}`, { params })
        .then(vendorResponse => {
          this.services = vendorResponse.data.services;
          this.vendorServices = vendorResponse.data.vendor_services.map(vendorService => ({
            ...vendorService,
            service: vendorResponse.data.services.find(service => service.id === vendorService.service_id),
          }));

          axios.get(`/v1/care-ecosystem/institutions/${this.client.institution_id}/vendors/${vendor.id}/services`)
            .then(response => {
              this.enabledServices = response.data.enabled_services.map(enabledService => {
                const vendorService = this.vendorServices.find(service => service.id === enabledService.vendor_service.id);
                vendorService.description_en = enabledService.description_en;
                vendorService.description_pt = enabledService.description_pt;
                vendorService.description_es = enabledService.description_es;
                vendorService.description_fr = enabledService.description_fr;

                return vendorService;
              });
            }).finally(() => {
              this.loadingVendorServices = false;
            });
        });
    },
    clearData() {
      this.enabledServices = [];
      this.vendorServices = [];
    },
    close() {
      this.clearData();
      this.$emit('close');
    },
    editVendorService(service) {
      const vendorService = this.vendorServices.find(serviceObj => serviceObj.id === service.id);

      this.editingVendorService = { ...vendorService };
    },
    updatedService(service) {
      this.enabledServices = this.enabledServices.map(enabledService => {
        if (enabledService.id === service.id) {
          enabledService.description_en = service.description_en;
          enabledService.description_pt = service.description_pt;
          enabledService.description_es = service.description_es;
          enabledService.description_fr = service.description_fr;
        }

        return enabledService;
      });
    },
    closeEditVendorServiceModal() {
      this.editingVendorService = null;
    },
  },
};
</script>
