<script>
import {
  eligibilityCriteriaOptions, implementationStatusOptions, benefitsEnrolledEligibleOptions, memberPaymentOptions, channelOptInOptions,
} from '@/scripts/definitions';


const indexForThisTab = 10;

export default {
  name: 'TabMind',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      eligibilityCriteriaOptions,
      implementationStatusOptions,
      benefitsEnrolledEligibleOptions,
      memberPaymentOptions,
      channelOptInOptions,
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    return {};
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">Mind</span>
      <span v-else class="text-success">Mind</span>
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">

        <b-tab title="General" active>
          <b-row>
            <b-col cols="4">
              <b-form-group id="group-client-mind.enabled" label="Is Mind enabled:"
                label-for="input-client-mind.enabled">
                <b-form-radio v-model="client.configuration.mind.enabled" name="mind.enabled-radios"
                  :value="true">Enabled</b-form-radio>
                <b-form-radio v-model="client.configuration.mind.enabled" name="mind.enabled-radios"
                  :value="false">Disabled</b-form-radio>
              </b-form-group>

              <b-form-group id="group-client-mind-support_email" label="Support Email:"
                label-for="input-client-mind-support_email" description="">
                <b-form-input id="input-client-mind-support_email" v-model.trim="client.configuration.mind.support_email"
                  type="text" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-mind-implementation-status" description=""
                              label="Implementation Status:" label-for="input-client-mind-implementation-status">
                <b-form-select v-model="client.configuration.mind.implementation_status"
                    :options="implementationStatusOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-mind-eligibility-criteria" description=""
                            label="Eligibility Criteria:" label-for="input-client-mind-eligibility-criteria">
                <b-form-select v-model="client.configuration.mind.eligibility_criteria"
                  :options="eligibilityCriteriaOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-mind-benefits-enrolled-eligible" description=""
                            label="Benefits Enrolled/Eligible:" label-for="input-client-mind-benefits-enrolled-eligible">
                <b-form-select v-model="client.configuration.mind.benefits_enrolled_eligible"
                  :options="benefitsEnrolledEligibleOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-mind-member-payment" description=""
                            label="Member Payment:" label-for="input-client-mind-member-payment">
                <b-form-select v-model="client.configuration.mind.member_payment"
                  :options="memberPaymentOptions">
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="8">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-mind.navigator_visible"
                      v-model="client.configuration.mind.navigator_visible"
                      name="checkbox-client-mind.navigator_visible" />
                    Show on navigator even if it is not available
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill
                          title="Unit card will be displayed in the Navigator even if not enabled for the client"
                          variant="primary">
                    <feather type="info"/>
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-mind.toggles.allow_minor_enrollments"
                      v-model="client.configuration.mind.toggles.allow_minor_enrollments"
                      name="checkbox-client-mind.toggles.allow_minor_enrollments" />
                    Allow enrollments by minors
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.capacity" label="Capacity:"
                    label-for="input-client-mind.capacity">
                    <template #label>
                      Enrollment Cap:
                      <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                        title="Use zero for endless capacity.">
                        <feather type="info" />
                      </b-badge>
                    </template>
                    <b-form-input number id="input-client-mind.capacity" v-model="client.configuration.mind.capacity"
                      type="number" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.salesforce_account_id" label="SalesForce program_id:"
                    label-for="input-client-mind.salesforce_account_id" description="">
                    <b-form-input id="input-client-mind.salesforce_account_id" maxlength='18'
                      v-model.trim="client.configuration.mind.salesforce_account_id" type="text" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.technical_launch_date" label="Technical launch date:"
                    label-for="input-client-mind.technical_launch_date" description="">
                    <b-datepicker id="input-client-mind.technical_launch_date"
                      v-model="client.configuration.mind.technical_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.marketing_launch_date" label="Marketing launch date:"
                    label-for="input-client-mind.marketing_launch_date" description="">
                    <b-datepicker id="input-client-mind.marketing_launch_date"
                      v-model="client.configuration.mind.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.unique_addressable_lives" label="Unique Addressable Lives:"
                    label-for="input-client-mind.unique_addressable_lives">
                    <b-form-input number id="input-client-mind.unique_addressable_lives"
                      v-model="client.configuration.mind.unique_addressable_lives" type="number"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Member app">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-mind.academy_enabled"
                  v-model="client.configuration.mind.academy_enabled" name="checkbox-client-mind.academy_enabled" />
                Academy enabled
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled it will show academy even if the member is actively enrolled in a program"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-mind.on_call_chat"
                  v-model="client.configuration.mind.on_call_chat" name="checkbox-client-mind.on_call_chat" />
                On call chat enabled
              </label>
              <b-col cols="6">
                <b-form-group id="group-client-mind.on_call_technical_launch_date" description=""
                  label="On-call technical launch date:" label-for="input-client-mind.on_call_technical_launch_date">
                  <b-datepicker id="input-client-mind.on_call_technical_launch_date"
                    v-model="client.configuration.mind.on_call_technical_launch_date"/>
                </b-form-group>
              </b-col>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled it will show On-Call chat available even if the member is actively enrolled in a program"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-mind.show_on_navigator"
                  v-model="client.configuration.mind.show_on_navigator" name="checkbox-client-mind.show_on_navigator" />
                Show on navigator/hub
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled the Mind card will be available on the Hub tab and on the navigator"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Call">
          <b-row>
            <b-col cols="6" v-show="false">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.allow_phone_call"
                                   v-model="client.configuration.mind.allow_phone_call"
                                   name="checkbox-client-mind.allow_phone_call" sync/>
                    Allow phone call
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-mind.toggles.enable_sendbird_video_call"
                      v-model="client.configuration.mind.toggles.enable_sendbird_video_call"
                      name="checkbox-client-mind.toggles.enable_sendbird_video_call" />
                    Sendbird video calls
                  </label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="6">
              <b-form-group id="group-client-mind.call_type" description="Use intake flow or treatment flow"
                label="Video call PHS and treatment PHS:" label-for="input-client-mind.call_type">
                <b-form-radio v-model="client.configuration.mind.call_type" name="mind.call_type-radios"
                  value="intake_flow">Can be different (intake flow)
                </b-form-radio>
                <b-form-radio v-model="client.configuration.mind.call_type" name="mind.call_type-radios"
                  value="treatment_flow">Should be the same (treatment flow)
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-mind.number_of_days_to_schedule" label="Number of days to schedule:"
                label-for="input-client-mind.number_of_days_to_schedule">
                <b-form-input number id="input-client-mind.number_of_days_to_schedule"
                  v-model="client.configuration.mind.number_of_days_to_schedule" type="number" placeholder="30"
                  required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group id="group-client-mind.video_call.buffer" label="Interval between calls:"
                label-for="input-client-mind.video_call.buffer">
                <b-form-input number id="input-client-mind.video_call.buffer"
                  v-model="client.configuration.mind.video_call.buffer" type="number" placeholder="0"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-mind.video_call.duration" label="Call duration:"
                label-for="input-client-mind.video_call.duration">
                <b-form-input number id="input-client-mind.video_call.duration"
                  v-model="client.configuration.mind.video_call.duration" type="number" placeholder="30"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-mind.minimum_scheduling_distance_hours"
                label="First slot available distance in hours:"
                label-for="input-client-mind.minimum_scheduling_distance_hours">
                <b-form-input number id="input-client-mind.minimum_scheduling_distance_hours"
                  v-model="client.configuration.mind.minimum_scheduling_distance_hours" type="number" placeholder="12"
                  required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Clinical">
          <b-col cols="12">
            <label>
              <toggle-button sync id="checkbox-client-mind.institution_configs.automatic_reassessments"
                v-model="client.configuration.mind.institution_configs.automatic_reassessments"
                name="checkbox-clientmind.institution_configs.automatic_reassessments" />
              Automatic reassessments
            </label>
            <b-badge v-b-tooltip.hover class="float-right" pill
                  title="When disabled reassessments to members will not be triggered"
                  variant="primary">
              <feather type="info"/>
            </b-badge>
          </b-col>
        </b-tab>

        <b-tab title="Marketing">
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-mind.marketing_preferences.eligibility_statement_toggle"
                                v-model="client.configuration.mind.marketing_preferences.eligibility_statement_toggle"
                                name="checkbox-client-mind.marketing_preferences.eligibility_statement_toggle" sync/>
                  Eligibility statement
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                   Eligibility Statement - Disabled if toggle is off on the previous field
                  <b-textarea
                    v-model="client.configuration.mind.marketing_preferences.eligibility_statement"
                    :disabled="!client.configuration.mind.marketing_preferences.eligibility_statement_toggle"
                    class="mt-4" size="lg"
                    rows="5"/>
                </label>
              </b-col>
              <b-col cols="12">
                <b-form-group id="radiobutton-client-mind.marketing_preferences.marketing_access" label="Marketing Access:">
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'very high'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">Very high
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'high'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">High
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'medium'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">Medium
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'low'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'very low'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">Very Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'not standard'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">Not Standard
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.mind.marketing_preferences.marketing_access" :value="'no access'"
                                name="radiobutton-client-mind.marketing_preferences.marketing_access">No Access
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Channel Opt-in">
                  <b-form-checkbox-group id="checkbox-client-mind.marketing_preferences.channel_opt_in"
                                          v-model="client.configuration.mind.marketing_preferences.channel_opt_in"
                                          :options="channelOptInOptions" name="checkbox-client-mind.marketing_preferences.channel_opt_in"
                                          stacked>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="6">
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.disclaimer_toggle"
                                  v-model="client.configuration.mind.marketing_preferences.disclaimer_toggle"
                                  name="checkbox-client-mind.marketing_preferences.disclaimer_toggle" sync/>
                    Disclaimer
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    Disclaimer - Disabled if toggle is off on the previous field
                    <b-textarea
                      v-model="client.configuration.mind.marketing_preferences.disclaimer"
                      :disabled="!client.configuration.mind.marketing_preferences.disclaimer_toggle"
                      class="mt-4" size="lg"
                      rows="5"/>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.allow_thematic_campaigns"
                                  v-model="client.configuration.mind.marketing_preferences.allow_thematic_campaigns"
                                  name="checkbox-client-mind.marketing_preferences.allow_thematic_campaigns" sync/>
                    Allow thematic campaingns
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.allow_free_language"
                                  v-model="client.configuration.mind.marketing_preferences.allow_free_language"
                                  name="checkbox-client-mind.marketing_preferences.allow_free_language" sync/>
                    Allow free language
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.requires_content_changes_approval"
                                  v-model="client.configuration.mind.marketing_preferences.requires_content_changes_approval"
                                  name="checkbox-client-mind.marketing_preferences.requires_content_changes_approval" sync/>
                    Requires content changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.requires_aesthetic_changes_approval"
                                  v-model="client.configuration.mind.marketing_preferences.requires_aesthetic_changes_approval"
                                  name="checkbox-client-mind.marketing_preferences.requires_aesthetic_changes_approval" sync/>
                    Requires aesthetic changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.sms_allowed"
                                  v-model="client.configuration.mind.marketing_preferences.sms_allowed"
                                  name="checkbox-client-mind.marketing_preferences.sms_allowed" sync/>
                    SMS allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-mind.marketing_preferences.emails_allowed"
                                  v-model="client.configuration.mind.marketing_preferences.emails_allowed"
                                  name="checkbox-client-mind.marketing_preferences.emails_allowed" sync/>
                    Emails allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.marketing_preferences.member_reference_name"
                                label="Member reference name:"
                                label-for="input-client-mind.marketing_preferences.member_reference_name">
                    <b-form-input id="input-client-mind.marketing_preferences.member_reference_name"
                                  v-model.trim="client.configuration.mind.marketing_preferences.member_reference_name"
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-mind.marketing_preferences.date_of_first_communication"
                                label="Date of first communication sent:"
                                label-for="input-client-mind.marketing_preferences.date_of_first_communication">
                    <b-form-datepicker id="input-client-mind.marketing_preferences.date_of_first_communication"
                                  v-model.trim="client.configuration.mind.marketing_preferences.date_of_first_communication"/>
                  </b-form-group>
                </b-col>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
