<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TabExtraConfiguration',
  props: {
    client: Object,
    editing: Boolean,
  },
  computed: {
    ...mapGetters({
      userCanAccessClaims: 'User/canAccessClaims',
      canAccessSftpFileImporters: 'User/canAccessSftpFileImporters',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      environmentConfigs: 'Core/getEnvironmentConfigs',
    }),
    onboardingPageURL() {
      let onboardingUrl = this.$store.getters['Core/getEnvironmentConfigs'].onboarding_url;
      if (onboardingUrl === undefined) {
        onboardingUrl = '';
      }
      onboardingUrl = onboardingUrl.replace(/\/$/, '');
      return `${onboardingUrl}/c/${this.client.reference}`;
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      Extra Configs
    </template>
    <b-card-text>
      <b-list-group>
        <b-list-group-item :href="onboardingPageURL" target="_blank" variant="primary" v-if="onboardingPageURL">
          <feather type="globe"></feather>
          Onboarding page
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/${client.id}/members`">
          <feather type="users"></feather>
          Enrollments
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/clients/${client.id}/eligibility`">
          <feather type="user-check"></feather>
          Eligibility configs
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/integrations/${client.id}`">
          <feather type="shuffle"></feather>
          SSO & External Eligibility
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/clients/${client.id}/sso/integrations`">
          <feather type="star"></feather>
          [New] SAML SSO Integrations
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/clients/${client.id}/billing`">
          <feather type="dollar-sign"></feather>
          Billing
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/eligibility/${client.id}/checker`">
          <feather type="check-square"></feather>
          Test eligibility
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/clients/${client.id}/referrals`">
          <feather type="map"></feather>
          Referral states configuration
        </b-list-group-item>
        <b-list-group-item :to="`/onboarding/clients/${client.id}/invitations`">
          <feather type="send"></feather>
          Member invitation
        </b-list-group-item>
        <b-list-group-item :to="`/client/${client.id}/manage-professionals`">
          <feather type="briefcase"></feather>
          Manage professionals
        </b-list-group-item>
        <b-list-group-item v-if="canAccessSftpFileImporters && isSftpSettingsEnabled" :to="`/onboarding/clients/${client.id}/generic-importer`">
          <feather type="activity" class="mr-1"></feather>
          Generic Importer
        </b-list-group-item>
      </b-list-group>

    </b-card-text>
  </b-tab>
</template>
