<template>
  <b-modal v-if="visible" size="lg" button-size="sm" :visible="visible" centered @hidden="close" @cancel="close">
    <template #modal-title>
      <h6>Add New Slug</h6>
    </template>

    <b-row class="mt-3">
      <b-col>
        <b-form-group id="group-client-new-slug" label="Slug Name" label-for="input-client-new-slug" description="">
                <b-form-input id="input-client-new-slug" v-model.trim="newSlug" type="text" placeholder="" required/>
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>
      <b-button variant="primary" @click="save" :disabled="!isValidForm">Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AddNewSlugModal',
  components: {
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      newSlug: { required },
    };
  },
  data() {
    return {
      newSlug: '',
      errorMessages: {
        required: 'This field is required',
      },
    };
  },
  props: {
    client: Object,
    visible: Boolean,
  },
  computed: {
    isValidForm() {
      return !this.$v.newSlug.$invalid;
    },
  },
  methods: {
    save() {
      if (this.isValidForm) {
        this.client.slugs.push({
          slug: this.newSlug,
          enabled: true,
        });
        this.newSlug = '';
        this.close();
      } else {
        this.$noty.error('Please fill all required fields');
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
