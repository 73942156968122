<script>
import pagination from '@/mixins/lists/pagination';
import axios from 'axios';
import AddNewVendorModal from './EcosystemVendors/AddNewVendorModal.vue';
import EditVendorModal from './EcosystemVendors/EditVendorModal.vue';

export default {
  name: 'EcosystemVendors',
  mixins: [ pagination ],
  components: {
    AddNewVendorModal,
    EditVendorModal,
  },
  props: {
    client: Object,
    activeTab: String,
  },
  data() {
    return {
      loadingPage: false,
      editingVendor: null,
      vendors: [],
      addVendorModalVisible: false,
    };
  },
  watch: {
    client(value) {
      if (value) {
        this.fetchVendors();
      }
    },
    vendors(value) {
      if (value?.length) {
        const { vendorId } = this.$route.query;
        if (vendorId) {
          const currentVendor = value.find(vendor => vendor.id === Number(vendorId));
          this.editVendor(currentVendor);
          this.cleanupQueryParams();
        }
      }
    },
  },
  methods: {
    cleanupQueryParams() {
      this.$router.replace({ query: null });
    },
    async safeDeleteVendor(vendor) {
      const canBeDeleted = await this.confirmVendorDeletion(vendor);
      return canBeDeleted ? this.deleteClientVendor(vendor) : false;
    },
    async confirmVendorDeletion(vendor) {
      try {
        return await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete vendor ${vendor.name} ?`);
      } catch {
        return false;
      }
    },
    deleteClientVendor(vendor) {
      axios.delete(`v1/care-ecosystem/institutions/${this.client.institution_id}/vendor-client/${vendor.id}`)
        .then(() => {
          this.$noty.success(`Vendor ${vendor.name} deleted successfully`);

          this.fetchVendors();
        }).catch(err => {
          this.$noty.error(`Something went wrong deleting vendor '${vendor.name}': ${err}`);
        });
    },
    editVendor(vendor) {
      this.editingVendor = vendor;
    },
    openAddVendorModal() {
      this.addVendorModalVisible = true;
    },
    fetchVendors() {
      if (!this.client?.institution_id) {
        return;
      }

      this.loadingPage = true;
      const page = this.pagination.currentPage - 1;
      const params = {
        limit: this.pagination.itemsPerPage,
        offset: page * this.pagination.itemsPerPage,
      };

      axios.get(`v1/care-ecosystem/institutions/${this.client.institution_id}/vendors`, { params })
        .then(response => {
          this.vendors = response.data.entries;
          this.pagination.totalRows = response.data.total;
        }).catch(err => {
          this.$noty.error(`Failed to fetch care ecosystem vendors: ${err}`);
        }).finally(() => {
          this.loadingPage = false;
        });
    },
    onPageChange() {
      this.fetchVendors();
    },
    onAddNewVendorModalClose(toRefreshData) {
      this.addVendorModalVisible = false;

      if (toRefreshData) {
        this.fetchVendors();
      }
    },
    onEditVendorModalClose() {
      this.editingVendor = null;
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1" :active="activeTab === 'EcosystemVendors'">
    <template #title>
      Vendors
      <feather class="float-right" type="check"></feather>
    </template>
    <b-card-text>
      <section class="general-configs">
        <h6>General Configs</h6>
        <b-form-group class="mt-4" label="Follows Standard Ecosystem Rules?*">
          <b-form-radio-group id="client.configurations.follows_care_ecosystem_rules"
            v-model="client.configuration.follows_care_ecosystem_rules">
            <b-form-radio :value="1">Yes</b-form-radio>
            <b-form-radio :value="0">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group class="mt-4" label="Client General Guidelines">
          <b-form-textarea
            id="client.configuration.general_guidelines"
            v-model="client.configuration.general_guidelines"
            placeholder="Share important or helpful information regarding the customer and/or their ecosystem"
            rows="3"
            maxlength="25000">
          </b-form-textarea>
        </b-form-group>
        <b-form-group class="mt-4" label="Preferred Vendor for Referral">
          <b-form-select
            id="client.configuration.preferred_vendor"
            v-model="client.configuration.preferred_vendor"
            :options="vendors"
            value-field="id"
            text-field="name">
          </b-form-select>
        </b-form-group>
      </section>
      <section id="vendor-list-module">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <h6 class="my-3">Ecosystem vendors list</h6>

            <b-button variant="primary" class="ml-auto" @click="openAddVendorModal">
              Add vendor
            </b-button>
          </div>
        </div>
        <table class="table -mx_0 -mb_1">
          <thead class="thead-light">
            <tr>
              <th scope="col">Vendor ID</th>
              <th scope="col">Vendor Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody v-if="vendors.length">
            <tr v-for="(vendor, vendorIndex) in vendors" :key="vendorIndex">
              <td class="align-middle"> {{ vendor.id }}</td>
              <td class="align-middle"> {{ vendor.name }}</td>
              <td class="align-middle table__item-actions">
                <b-button v-b-tooltip.hover title="Edit" size="sm" variant="light" @click="editVendor(vendor)">
                  <feather type="edit-2"></feather>
                </b-button>
                <b-button v-b-tooltip.hover size="sm" title="Delete" variant="light" @click="safeDeleteVendor(vendor)">
                  <feather type="trash"></feather>
                </b-button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center">No vendors added, add one using the “Add vendor“ button above</td>
            </tr>
          </tbody>
        </table>
        <div class="row pt-2" v-if="vendors.length">
          <div class="col-12 d-flex justify-content-center">
            <b-pagination :disabled="loadingPage" @change="changePage" v-model="pagination.currentPage"
              :total-rows="pagination.totalRows" :per-page="pagination.itemsPerPage" size="md">
            </b-pagination>
          </div>
        </div>
      </section>

      <edit-vendor-modal :client="client" :vendor="editingVendor" @close="onEditVendorModalClose" />
      <add-new-vendor-modal :client="client" :institution-vendors="vendors" :visible="addVendorModalVisible" @close="onAddNewVendorModalClose" />
    </b-card-text>
  </b-tab>
</template>

<style lang="scss">
.table {
  table-layout: fixed;


  &__item {
    &:hover {
      cursor: pointer;
      background-color: $context-color-7;
    }

    td {
      align-items: center;
      @extend %compact-text;
    }

    &-actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.25rem;
      text-overflow: initial !important;

      &-button {
        min-width: initial !important;
      }
    }
  }
}
</style>
