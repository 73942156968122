<script>
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';

const indexForThisTab = 5;

export default {
  name: 'TabMarketing',
  props: {
    tab: Object,
    client: Object,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data: () => ({
    marketing_plans: {
      loaded: false,
      loading: false,
      data: [],
      active_marketing_plan: null,
    },
    incentivesOptInOptions: [
      { text: 'Pre-enrollment', value: 'pre_enrollment' },
      { text: 'Post-enrollment', value: 'post_enrollment' },
    ],
  }),
  computed: {
    ...mapGetters({
      isMarketingIntegrationEnabled: 'Core/isMarketingIntegrationEnabled',
    }),

    overlay() {
      if (this.marketing_plans.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching marketing plan',
        };
      }
      return {
        show: false,
        icon: '',
        animation: '',
        text: '',
      };
    },

    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
    currentTab(value) {
      if (!this.isMarketingIntegrationEnabled) return;

      if (indexForThisTab === value && this.marketing_plans.loaded === false) {
        this.fetchMarketingPlan();
      }
    },
  },
  validations() {
    return {};
  },
  methods: {
    fetchMarketingPlan() {
      this.marketing_plans.loading = true;
      return axios.get('v1/marketing/marketing-plan?', { params: { client_id: this.client.id } })
        .then(async response => {
          if (!response.data.marketing_plans || response.data.marketing_plans.length < 1) {
            return;
          }
          // eslint-disable-next-line prefer-destructuring
          this.marketing_plans.data = await Promise.all(response.data.marketing_plans.map(async m => {
            m.touchpoints = await this.fetchMarketingTouchPoints(m.uuid);
            return m;
          }));
          this.marketing_plans.active_marketing_plan = this.marketing_plans.data?.find(plan => plan.status === 'active');
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch marketing plan', e);
        }).finally(() => {
          this.marketing_plans.loading = false;
          this.marketing_plans.loaded = true;
        });
    },

    fetchMarketingTouchPoints(marketingPlanUUID) {
      return axios.get('v1/marketing/touchpoint', { params: { marketing_plan_uuid: marketingPlanUUID } })
        .then(response => response.data)
        .catch(e => Vue.prototype.$noty.error('Cannot fetch touchpoints', e));
    },
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      Marketing
      <feather :type="tabIcon" class="float-right"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">
        <b-tab active title="General">
          <b-row>
            <b-col xl="4">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-toggles.send_drop_off_emails"
                                  v-model="client.configuration.toggles.send_drop_off_emails"
                                  name="checkbox-client-toggles.send_drop_off_emails"
                                  sync/>
                    Send drop-off emails
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-toggles.show_on_marketing_pages"
                                  v-model="client.configuration.toggles.show_on_marketing_pages"
                                  name="checkbox-client-toggles.show_on_marketing_pages"
                                  sync/>
                    Show on marketing pages
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-configuration.marketing.co-branded-logo" label="Co-branded Logo URL:"
                                label-for="input-client-configuration.marketing.co-branded-logo">
                    <b-form-input id="input-client-configuration.marketing.co-branded-logo"
                                  v-model.trim="client.configuration.marketing.co_branded_logo"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Incentive Opt-in">
                    <b-form-checkbox-group
                      id="checkbox-client-toogles.incentive_opt_in"
                      v-model="client.configuration.incentives_opt_in"
                      :options="incentivesOptInOptions"
                      stacked />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab active title="Marketing plan">
          <template v-if="isMarketingIntegrationEnabled">
            <b-overlay :show="overlay.show">
              <b-col xl="8" v-if="!marketing_plans.active_marketing_plan?.uuid">
                  <b-button variant="primary" :to="`/client/${client.id}/marketing-plan`">
                    <feather type="plus"></feather>
                    Create marketing plan
                  </b-button>
              </b-col >
              <template #overlay>
                <div class="text-center mt-5">
                  <b-icon :animation="overlay.animation" :icon="overlay.icon" font-scale="3"></b-icon>
                  <p id="cancel-label">{{ overlay.text }}</p>
                </div>
              </template>
                <b-col class="mt-4" xl="8" v-model="marketing_plans.data" v-if="marketing_plans.data.length > 0">
                  <b-row v-for="(plan, planKey) in marketing_plans.data" :key="planKey">
                    <b-col cols="8">
                      <ul class="list-unstyled">
                        <li><b>Key:</b>{{plan.key}}</li>
                        <li><b>Journey ID:</b>{{plan.journey_id}}</li>
                        <li><b>Type:</b>{{plan.type}}</li>
                        <li><b>Number of touchpoints:</b>{{ plan.touchpoints?.total }}</li>
                        <li><b>Start date:</b>{{plan.start_date}}</li>
                        <li><b>Status:</b> {{plan.status}}</li>
                      </ul>
                    </b-col>
                    <b-col cols="4">
                      <b-button v-if="plan.status != 'finished' && plan.status != 'canceled'"
                        variant="primary" class="float-right" :to="`/client/${client.id}/marketing-plan/${plan.uuid}`">
                        <feather type="edit"></feather>
                        Configure plan
                      </b-button>
                      <b-button v-else variant="primary" class="float-right pl-4 pr-4" :to="`/client/${client.id}/marketing-plan/${plan.uuid}`">
                        <feather type="eye"></feather>
                        Check plan
                      </b-button>
                    </b-col>
                  </b-row>
              </b-col>
            </b-overlay>
            </template>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-tab>
</template>
