<template>
  <b-modal
    v-if="isVisible"
    size="lg"
    button-size="sm"
    :visible="isVisible"
    centered
    @hidden="close"
    @cancel="close">
    <template #modal-title>
      <h6>{{ vendorService.service.name }} for {{ client.name }} - {{ vendor.name }} default configs</h6>
    </template>

    <b-row>
      <b-col>
        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.en.label"
          :state="isValid('updatedService.description_en')"
          :invalid-feedback="handleError('updatedService.description_en')">
          <b-form-textarea v-model="updatedService.description_en"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.pt.label">
          <b-form-textarea v-model="updatedService.description_pt"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.es.label">
          <b-form-textarea v-model="updatedService.description_es"/>
        </b-form-group>

        <b-form-group
          label-class="p-0"
          :description="DEFAULT_VENDOR_SERVICE_DESCRIPTION"
          :label="modalFields.fr.label">
          <b-form-textarea v-model="updatedService.description_fr"/>
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>

      <b-button
        variant="primary"
        @click="saveChanges"
        :disabled="!isValidForm">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import { DEFAULT_VENDOR_SERVICE_DESCRIPTION } from '@/scripts/constants';

export default {
  name: 'EditVendorServiceModal',
  props: {
    client: Object,
    vendorService: Object,
    vendor: Object,
  },
  data() {
    return {
      DEFAULT_VENDOR_SERVICE_DESCRIPTION,
      errorMessages: {
        required: 'This field is required',
      },
      updatedService: null,
    };
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      updatedService: {
        description_en: { required },
      },
    };
  },
  watch: {
    vendorService(value) {
      this.updatedService = value;
    },
  },
  computed: {
    isValidForm() {
      return !this.$v.updatedService.$invalid;
    },
    modalFields() {
      return {
        en: {
          label: `[EN] ${this.vendor.name} - ${this.vendorService.service.name} - Info for the member`,
        },
        pt: {
          label: `[PT] ${this.vendor.name} - ${this.vendorService.service.name} - Info for the member`,
        },
        es: {
          label: `[ES] ${this.vendor.name} - ${this.vendorService.service.name} - Info for the member`,
        },
        fr: {
          label: `[FR] ${this.vendor.name} - ${this.vendorService.service.name} - Info for the member`,
        },
      };
    },
    isVisible() {
      return !!this.vendorService;
    },
  },
  methods: {
    saveChanges() {
      if (this.isValidForm) {
        this.$emit('updated-service', this.updatedService);

        this.close();
      } else {
        this.$noty.error('Please fill all required fields');
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
