<template>
  <b-modal
    v-if="visible"
    size="lg"
    button-size="sm"
    :visible="visible"
    centered
    @hidden="close"
    @cancel="close">
    <template #modal-title>
      <h6>Add New Vendor</h6>
    </template>

    <b-row>
      <b-col>
        <b-form-group
          label-class="p-0"
          label="Vendor Name*"
          :state="isValid('vendor')"
          :invalid-feedback="handleError('vendor')">
          <v-select
              id="vendors-selector"
              :options="vendors"
              :searchable="true"
              v-model="selectedVendorObj"/>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      v-if="loadingVendorServices"
      class="w-100 h-100 d-flex align-items-center justify-content-center">
      <loading-content />
    </div>

    <b-row class="mt-3" v-if="vendor && !loadingVendorServices">
      <b-col>
        <h6>Vendor Services</h6>

        <b-form-group
          v-if="Array.isArray(vendorServices) && vendorServices.length"
          v-slot="{ ariaDescribedby }"
          label="Vendor Services"
          :state="isValid('enabledServices')"
          :invalid-feedback="handleError('enabledServices')"
          label-sr-only>
          <b-form-checkbox
            v-for="(vendorService, index) in vendorServices"
            :key="`service-${index}`"
            :id="`service-${index}`"
            :ref="`serviceCheckbox-${index}`"
            v-model="enabledServices"
            :value="vendorService"
            name="vendor-service"
            size="lg"
            :aria-describedBy="ariaDescribedby"
            switch
            class="my-1">
            <div class="d-flex align-items-center">
              <div
                v-b-tooltip.hover
                :style="{ fontSize: '1rem !important' }"
                class="cursor-pointer"
                :title="getServiceTooltip(vendorService.service.id)">
                {{ vendorService.service.name }}
              </div>

              <b-button
                class="ml-2"
                variant="link"
                size="sm"
                :disabled="!isChecked(vendorService.id)"
                @click.prevent.stop="editVendorService(vendorService)">
                <feather type="edit"></feather>
                Edit
              </b-button>
            </div>
          </b-form-checkbox>
        </b-form-group>

        <div v-else>
          No vendor services added, add one on the Care ecosystem > Ecosystem vendors page
        </div>
      </b-col>
    </b-row>

    <template #modal-footer="{ hide }">
      <b-button variant="secondary" @click="hide('cancel')">Cancel</b-button>

      <b-button
        variant="primary"
        @click="save"
        :disabled="!isValidForm">
        Save
      </b-button>
    </template>

    <edit-vendor-service-modal
      :client="client"
      :vendor="vendor"
      :vendor-service="editingVendorService"
      @updated-service="updatedService"
      @close="closeEditVendorServiceModal" />
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import LoadingContent from '@/components/LoadingContent.vue';
import VSelect from '@alfsnd/vue-bootstrap-select';
import axios from 'axios';
import EditVendorServiceModal from './EditVendorServiceModal.vue';

export default {
  name: 'AddNewVendorModal',
  components: {
    EditVendorServiceModal,
    LoadingContent,
    VSelect,
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      vendor: { required },
      enabledServices: { required },
    };
  },
  data() {
    return {
      errorMessages: {
        required: 'This field is required',
      },
      vendor: null,
      selectedVendorObj: null,
      enabledServices: [],
      vendorServices: [],
      services: [],
      loadingVendorServices: false,
      editingVendorService: null,
      allVendors: [],
      editVendorServiceModalVisible: false,
    };
  },
  props: {
    client: Object,
    visible: Boolean,
    institutionVendors: Array,
  },
  computed: {
    isValidForm() {
      return !this.$v.vendor.$invalid && !this.$v.enabledServices.$invalid;
    },
    vendors() {
      const nonInstitutionVendors = this.allVendors?.filter(vendor => !this.institutionVendors.some(v => v.id === vendor.id)) || [];

      nonInstitutionVendors.sort((a, b) => a.name.localeCompare(b.name));

      return nonInstitutionVendors.map(vendor => ({ value: vendor, text: vendor.name }));
    },
  },
  watch: {
    selectedVendorObj(value) {
      this.vendor = value ? value.value : null;
    },
    vendor(value) {
      if (value) {
        this.loadingVendorServices = true;
        this.enabledServices = [];
        this.vendorServices = [];

        const params = {
          fetch_vendor_services: true,
          fetch_services: true,
        };

        axios.get(`/v1/care-ecosystem/vendors/${value.id}`, { params })
          .then(response => {
            this.services = response.data.services;
            this.vendorServices = response.data.vendor_services.map(vendorService => ({
              ...vendorService,
              service: response.data.services.find(service => service.id === vendorService.service_id),
            }));
          }).catch(err => {
            this.$noty.error(`Failed to fetch care ecosystem vendor: ${err}`);
          }).finally(() => {
            this.loadingVendorServices = false;
          });
      }
    },
  },
  methods: {
    getServiceTooltip(id) {
      return this.services.find(service => service.id === id)?.description;
    },
    validateIsArrayAndLenght(array) {
      return Array.isArray(array) && array.length;
    },
    isChecked(id) {
      return this.validateIsArrayAndLenght(this.enabledServices)
        && this.enabledServices.some(service => service.id === id);
    },
    save() {
      if (this.isValidForm) {
        const payload = {
          vendor_services: this.enabledServices,
        };

        axios.post(`/v1/care-ecosystem/institutions/${this.client.institution_id}/vendor-services`, payload)
          .then(() => {
            this.$noty.success('Vendor added successfully');

            this.close(true);
          }).catch(err => {
            this.$noty.error(`'Failed to add the vendor: ${err}`);
          });
      } else {
        this.$noty.error('Please fill all required fields');
      }
    },
    clearData() {
      this.selectedVendorObj = null;
      this.enabledServices = [];
      this.vendorServices = [];
    },
    close(refresh = false) {
      this.clearData();
      this.$emit('close', refresh);
    },
    fetchAllVendors() {
      const params = {
        limit: 9999, // workaround - to change in the future, check with the Backend team
      };

      axios.get('v1/care-ecosystem/vendors', { params })
        .then(response => {
          this.allVendors = response.data.entries;
        }).catch(err => {
          this.$noty.error(`Failed to fetch care ecosystem vendors: ${err}`);
        });
    },
    editVendorService(service) {
      const vendorService = this.vendorServices.find(serviceObj => serviceObj.id === service.id);

      this.editingVendorService = { ...vendorService };
    },
    updatedService(service) {
      this.enabledServices = this.enabledServices.map(enabledService => {
        if (enabledService.id === service.id) {
          enabledService.description_en = service.description_en;
          enabledService.description_pt = service.description_pt;
          enabledService.description_es = service.description_es;
          enabledService.description_fr = service.description_fr;
        }

        return enabledService;
      });
    },
    closeEditVendorServiceModal() {
      this.editingVendorService = null;
    },
  },
  mounted() {
    this.fetchAllVendors();
  },
};
</script>
