<script>
import { minValue, required, requiredIf } from 'vuelidate/lib/validators';
import {
  DptOnboarding, eligibilityCriteriaOptions, implementationStatusOptions, benefitsEnrolledEligibleOptions, memberPaymentOptions, channelOptInOptions,
} from '@/scripts/definitions';
import helpers from '@/helpers';

const { isOneOf } = helpers;
const indexForThisTab = 7;

export default {
  name: 'TabDPT',
  props: {
    tab: Object,
    client: Object,
    injuryAreaList: Array,
    editing: Boolean,
    credentialGroupsList: Array,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      serviceTypes: [],
      referralsMinorsOptions: [
        {
          value: 'OPENLOOP',
          text: 'Openloop',
        },
        {
          value: 'WHEEL',
          text: 'Wheel',
        },
      ],
      serviceTypesList: [
        {
          text: 'PT provider',
          value: 'pt_provider',
        },
        {
          text: 'Wellness',
          value: 'wellness',
        },
      ],
      kitTypeOptions: [
        {
          text: 'Sensors only',
          value: 'sensors',
        },
        {
          text: 'CV only',
          value: 'computer_vision',
        },
        {
          text: 'Sensors and CV',
          value: 'sensors_and_computer_vision',
        },
      ],
      automaticFormMappingOptions: [
        {
          value: 'general',
          text: 'General',
        }, {
          value: 'humana',
          text: 'Humana',
        },
      ],
      dptOnboardingOptions: DptOnboarding.map(ic => ({
        value: ic.optValue,
        text: ic.optLabel,
      })),
      eligibilityCriteriaOptions,
      implementationStatusOptions,
      benefitsEnrolledEligibleOptions,
      memberPaymentOptions,
      channelOptInOptions,
      currentDate: new Date(),
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    checkReferrals() {
      const wheelBalance = this.client.configuration.dpt.wheel_balance;
      const openLoopBalance = this.client.configuration.dpt.openloop_balance;

      return !Number.isInteger(wheelBalance) || !Number.isInteger(openLoopBalance);
    },
    checkSum() {
      let wheelBalance = -1;
      if (typeof this.client.configuration.dpt?.wheel_balance !== 'undefined' && this.client.configuration.dpt?.wheel_balance != null) {
        wheelBalance = this.client.configuration.dpt?.wheel_balance;
      }
      let openLoopBalance = -1;
      if (typeof this.client.configuration.dpt?.openloop_balance !== 'undefined' && this.client.configuration.dpt?.openloop_balance != null) {
        openLoopBalance = this.client.configuration.dpt?.openloop_balance;
      }

      return (wheelBalance + openLoopBalance) !== 100;
    },
    conditionsTableFields() {
      if (this.editing) {
        return [
          {
            key: 'label',
            label: 'Condition',
          },
          {
            key: 'enabled',
            label: 'Enabled',
          },
          {
            key: 'main_form',
            label: 'Main form',
          },
          {
            key: 'complementary_form',
            label: 'Complementary form',
          },
        ];
      }
      return [
        {
          key: 'label',
          label: 'Condition',
        },
        {
          key: 'enabled',
          label: 'Enabled',
        },
      ];
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
    highRiskDisabled() {
      return !this.$isSudo;
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
    'client.configuration.dpt.toggles.finish_members_program': {
      handler(newValue) {
        if (!newValue) {
          this.client.configuration.dpt.finish_members_program.scheduled_start_date = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    const isActive = this.client.configuration.dpt.enabled;

    if (!isActive) {
      return {};
    }

    return {
      client: {
        configuration: {
          dpt: {
            enabled: { required },
            minimum_scheduling_distance_hours: { minValue: minValue(0) },
            number_of_days_to_schedule: { minValue: minValue(0) },
            video_call: {
              buffer: { minValue: minValue(0) },
              duration: { minValue: minValue(10) },
              pt_provider_duration: { minValue: minValue(0) },
            },
            credential_group_id: {
              req: requiredIf(elem => elem.credentialRequired === true),
              minValue: minValue(1),
            },
            onboarding_type: {
              required,
              oneOf: isOneOf(this.dptOnboardingOptions),
            },
            kit_type: { required },
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">Thrive</span>
      <span v-else class="text-success">Thrive</span>
      <feather :type="tabIcon" class="float-right"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">
        <b-tab active title="General">
          <b-row>
            <b-col cols="6">
              <b-form-group id="group-client-dpt.enabled" label="Is Thrive enabled:" label-for="input-client-dpt.enabled">
                <b-form-radio v-model="client.configuration.dpt.enabled" :value="true"
                              name="dpt.enabled-radios">Enabled
                </b-form-radio>
                <b-form-radio v-model="client.configuration.dpt.enabled" :value="false"
                              name="dpt.enabled-radios">Disabled
                </b-form-radio>
              </b-form-group>

              <b-form-group label="Service types:">
                <b-form-checkbox-group id="checkbox-client-dpt.service_types"
                                       v-model="client.configuration.dpt.service_types"
                                       :options="serviceTypesList" name="checkbox-client-dpt.service_types"
                                       stacked></b-form-checkbox-group>
              </b-form-group>

              <b-form-group id="group-client-dpt.credentialRequired"
                            label="Assigned PT is required institution-specific credential?"
                            label-for="input-client-dpt.credentialRequired">
                <b-form-radio v-model="client.configuration.dpt.credentialRequired" :value="true"
                              name="dpt.credentialRequired-radios">Yes
                </b-form-radio>
                <b-form-radio v-model="client.configuration.dpt.credentialRequired" :value="false"
                              name="dpt.credentialRequired-radios">No
                </b-form-radio>
              </b-form-group>

              <b-col cols="12">
                <label>
                  <toggle-button sync id="checkbox-client-dpt.toggles.kit_shipped_only_after_video_call"
                                 v-model="client.configuration.dpt.toggles.kit_shipped_only_after_video_call"
                                 name="checkbox-client-dpt.toggles.kit_shipped_only_after_video_call"/>
                  Kit shipped after call (if applicable)
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill variant="primary"
                         title="If this config is on and the member has a call
                    scheduled, the kit will only be shipped after the call occurs">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.navigator_visible"
                                 v-model="client.configuration.dpt.navigator_visible"
                                 name="checkbox-client-dpt.navigator_visible" sync/>
                  Show on navigator even if it is not available
                </label>
                <b-badge v-b-tooltip.hover class="float-right"
                         pill title="Unit card will be displayed in the Navigator even if not enabled for the client"
                         variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.institution_configs.email_performances"
                                 v-model="client.configuration.dpt.institution_configs.email_performances"
                                 :value="true"
                                 name="checkbox-client-dpt.institution_configs.email_performances"
                                 sync/>
                  Email performances
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.institution_configs.email_professional_after_session"
                                 v-model="client.configuration.dpt.institution_configs.email_professional_after_session"
                                 :value="true"
                                 name="checkbox-client-dpt.institution_configs.email_professional_after_session"
                                 sync/>
                  Email professional after session
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.institution_configs.email_suggestions"
                                 v-model="client.configuration.dpt.institution_configs.email_suggestions"
                                 :value="false"
                                 name="checkbox-client-dpt.institution_configs.email_suggestions"
                                 sync/>
                  Email suggestions
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.toggles.allow_minor_enrollments"
                                 v-model="client.configuration.dpt.toggles.allow_minor_enrollments"
                                 name="checkbox-client-dpt.toggles.allow_minor_enrollments"
                                 sync/>
                  Allow enrollments by minors
                </label>
              </b-col>

            </b-col>

            <b-col cols="6">
              <b-row>
                <b-col cols="12" v-if="$isSudo">
                  <b-form-group id="dpt-onboarding-types" description=""
                                label="*Onboarding type:" label-for="input-client-configuration-dpt-onboarding">
                    <b-form-select v-model="client.configuration.dpt.onboarding_type"
                                   :options="dptOnboardingOptions"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt-implementation-status" description=""
                                label="Implementation Status:" label-for="input-client-dpt-implementation-status">
                    <b-form-select v-model="client.configuration.dpt.implementation_status"
                                   :options="implementationStatusOptions">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt-support_email" description=""
                                label="Support Email:" label-for="input-client-dpt-support_email">
                    <b-form-input id="input-client-dpt-support_email" v-model.trim="client.configuration.dpt.support_email"
                                  placeholder="" required type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt-eligibility-criteria" description=""
                                label="Eligibility Criteria:" label-for="input-client-dpt-eligibility-criteria">
                    <b-form-select v-model="client.configuration.dpt.eligibility_criteria"
                                   :options="eligibilityCriteriaOptions">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt-benefits-enrolled-eligible" description=""
                                label="Benefits Enrolled/Eligible:" label-for="input-client-dpt-benefits-enrolled-eligible">
                    <b-form-select v-model="client.configuration.dpt.benefits_enrolled_eligible"
                                   :options="benefitsEnrolledEligibleOptions">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt-member-payment" description=""
                                label="Member Payment:" label-for="input-client-dpt-member-payment">
                    <b-form-select v-model="client.configuration.dpt.member_payment"
                                   :options="memberPaymentOptions">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="client.configuration.dpt.credentialRequired">
                  <b-form-group id="group-client-configuration-dpt-credential_group_id" description=""
                                label="Credential group:" label-for="input-client-configuration-dpt-credential_group_id">
                    <b-form-select v-model="client.configuration.dpt.credential_group_id"
                                   :options="credentialGroupsList"></b-form-select>
                  </b-form-group>
                  <span :style="{ 'color': 'grey' }" class="ml-2"> * Select a credential group</span>
                </b-col>


                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-dpt.toggles.finish_members_program"
                      v-model="client.configuration.dpt.toggles.finish_members_program"
                      name="checkbox-client-dpt.toggles.finish_members_program" />
                    Finish members program
                  </label>
                </b-col>
                <b-col cols="12" v-if="client.configuration.dpt.toggles.finish_members_program">
                  <b-form-group id="group-client-dpt.finish_members_program.scheduled_start_date"
                                description=""
                                label="Finish members program scheduled start date:"
                                label-for="input-client-dpt.finish_members_program.scheduled_start_date">
                    <b-datepicker
                      id="input-client-dpt.finish_members_program.scheduled_start_date"
                      v-model="client.configuration.dpt.finish_members_program.scheduled_start_date"
                      :max="this.currentDate"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.capacity" label="Capacity:" label-for="input-client-dpt.capacity">
                    <template #label>
                      Enrollment Cap:
                      <b-badge v-b-tooltip.hover class="float-right" pill title="Use zero for endless capacity."
                               variant="primary">
                        <feather type="info"/>
                      </b-badge>
                    </template>
                    <b-form-input id="input-client-dpt.capacity" v-model="client.configuration.dpt.capacity" number
                                  required type="number"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.salesforce_account_id" description=""
                                label="SalesForce program_id:" label-for="input-client-dpt.salesforce_account_id">
                    <b-form-input id="input-client-dpt.salesforce_account_id"
                                  v-model.trim="client.configuration.dpt.salesforce_account_id" required maxlength='18'
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.technical_launch_date" description=""
                                label="Technical launch date:" label-for="input-client-dpt.technical_launch_date">
                    <b-datepicker id="input-client-dpt.technical_launch_date" v-model="client.configuration.dpt.technical_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.marketing_launch_date" description=""
                                label="Marketing launch date:" label-for="input-client-dpt.marketing_launch_date">
                    <b-datepicker id="input-client-dpt.marketing_launch_date" v-model="client.configuration.dpt.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.unique_addressable_lives" label="Unique Addressable Lives:"
                                label-for="input-client-dpt.unique_addressable_lives">
                    <b-form-input id="input-client-dpt.unique_addressable_lives"
                                  v-model="client.configuration.dpt.unique_addressable_lives"
                                  number type="number"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Loki">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.is_loki_enabled"
                                   v-model="client.configuration.dpt.toggles.is_loki_enabled"
                                   name="checkbox-client-dpt.toggles.is_loki_enabled"
                                   sync/>
                    Enable Loki Phase One
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.loki.phase_one_enrollment_percentage" description=""
                                label="Loki Phase One Enrollment %" label-for="input-client-dpt.loki.phase_one_enrollment_percentage">
                    <b-form-input id="input-client-dpt.loki.phase_one_enrollment_percentage"
                                  v-model="client.configuration.dpt.loki.phase_one_enrollment_percentage" number
                                  type="number"
                                  min="0" max="100"
                                  required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.is_loki_phase_two_enabled"
                                   v-model="client.configuration.dpt.toggles.is_loki_phase_two_enabled"
                                   name="checkbox-client-dpt.toggles.is_loki_phase_two_enabled"
                                   sync/>
                    Enable Loki Phase Two
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.loki.phase_two_enrollment_percentage" description=""
                                label="Loki Phase Two Enrollment %" label-for="input-client-dpt.loki.phase_two_enrollment_percentage">
                    <b-form-input id="input-client-dpt.loki.phase_two_enrollment_percentage"
                                  v-model="client.configuration.dpt.loki.phase_two_enrollment_percentage" number
                                  type="number"
                                  min="0" max="100"
                                  required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Member app">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button id="checkbox-client-dpt.academy_enabled"
                               v-model="client.configuration.dpt.academy_enabled"
                               name="checkbox-client-dpt.academy_enabled" sync/>
                Academy enabled
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                       title="When enabled it will show academy even if the member is actively enrolled in a program"
                       variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button id="checkbox-client-dpt.on_call_chat" v-model="client.configuration.dpt.on_call_chat"
                               name="checkbox-client-dpt.on_call_chat"
                               sync/>
                On call chat enabled
              </label>
              <b-col cols="6">
                <b-form-group id="group-client-dpt.on_call_technical_launch_date" description=""
                  label="On-call technical launch date:" label-for="input-client-dpt.on_call_technical_launch_date">
                  <b-datepicker id="input-client-dpt.on_call_technical_launch_date" v-model="client.configuration.dpt.on_call_technical_launch_date"/>
                </b-form-group>
              </b-col>
              <b-badge v-b-tooltip.hover class="float-right" pill
                       title="When enabled it will show On-Call chat available even if the member is actively enrolled in a program"
                       variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button id="checkbox-client-dpt.institution_configs.sword_go_enabled"
                               v-model="client.configuration.dpt.institution_configs.sword_go_enabled" :value="false"
                               name="checkbox-client-dpt.institution_configs.sword_go_enabled"
                               sync/>
                Allow GO Sessions
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                       title="GO sessions are standard sessions on the member app without sensors/CV"
                       variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button id="checkbox-client-dpt.show_on_navigator"
                               v-model="client.configuration.dpt.show_on_navigator"
                               name="checkbox-client-dpt.show_on_navigator" sync/>
                Show on navigator/hub
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                       title="When enabled the DPT card will be available on the Hub tab and on the navigator"
                       variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button id="checkbox-client-dpt.cbt_program" v-model="client.configuration.dpt.cbt_program"
                               name="checkbox-client-dpt.cbt_program"
                               sync/>
                CBT Program
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                       title="This toggle enables the CBT program for members enrolled in DPT"
                       variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-dpt.toggles.show_referrals"
                               v-model="client.configuration.dpt.toggles.show_referrals"
                               name="checkbox-client-dpt.toggles.show_referrals"/>
                Show referrals
              </label>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Call">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.allow_phone_call"
                                   v-model="client.configuration.dpt.allow_phone_call"
                                   name="checkbox-client-dpt.allow_phone_call" sync/>
                    Allow phone call
                  </label>
                </b-col>
                <b-col v-if="$isSudo" cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.show_fake_slots"
                                   v-model="client.configuration.dpt.toggles.show_fake_slots"
                                   name="checkbox-client-dpt.toggles.show_fake_slots"
                                   sync/>
                    Show fake slots
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.can_skip_video_call"
                                   v-model="client.configuration.dpt.toggles.can_skip_video_call"
                                   name="checkbox-client-dpt.toggles.can_skip_video_call"
                                   sync/>
                    Can skip call
                  </label>
                </b-col>
                <b-col v-show="false" cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.enable_sendbird_video_call"
                                   v-model="client.configuration.dpt.toggles.enable_sendbird_video_call"
                                   name="checkbox-client-dpt.toggles.enable_sendbird_video_call"
                                   sync/>
                    Sendbird video calls
                  </label>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-form-group id="group-client-dpt.call_type" description="Use intake flow or treatment flow"
                            label="Video call PT and treatment PT:" label-for="input-client-dpt.call_type">
                <b-form-radio v-model="client.configuration.dpt.call_type" name="dpt.call_type-radios"
                              value="intake_flow">Can be different (intake flow)
                </b-form-radio>
                <b-form-radio v-model="client.configuration.dpt.call_type" name="dpt.call_type-radios"
                              value="treatment_flow">Should be the same (treatment flow)
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="4">
              <b-form-group id="group-client-dpt.number_of_days_to_schedule" label="Number of days to schedule:"
                            label-for="input-client-dpt.number_of_days_to_schedule">
                <b-form-input id="input-client-dpt.number_of_days_to_schedule"
                              v-model="client.configuration.dpt.number_of_days_to_schedule"
                              number placeholder="30" required
                              type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-dpt.video_call.buffer" label="Interval between calls:"
                            label-for="input-client-dpt.video_call.buffer">
                <b-form-input id="input-client-dpt.video_call.buffer"
                              v-model="client.configuration.dpt.video_call.buffer"
                              number placeholder="0" required
                              type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-dpt.video_call.duration" label="Call duration:"
                            label-for="input-client-dpt.video_call.duration">
                <b-form-input id="input-client-dpt.video_call.duration"
                              v-model="client.configuration.dpt.video_call.duration"
                              number placeholder="30" required
                              type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-dpt.minimum_scheduling_distance_hours"
                            label="First slot available distance in hours:"
                            label-for="input-client-dpt.minimum_scheduling_distance_hours">
                <b-form-input id="input-client-dpt.minimum_scheduling_distance_hours"
                              v-model="client.configuration.dpt.minimum_scheduling_distance_hours"
                              number placeholder="12" required
                              type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-dpt.video_call-pt_provider_duration" label="PT provider call duration:"
                            label-for="input-client-dpt.video_call-pt_provider_duration">
                <template #label>
                  PT provider call duration:
                  <b-badge v-b-tooltip.hover class="float-right" pill
                           title="If this config is set as zero, default duration will be applied for PT Provider."
                           variant="primary">
                    <feather type="info"/>
                  </b-badge>
                </template>
                <b-form-input id="input-client-dpt.video_call-pt_provider_duration"
                              v-model="client.configuration.dpt.video_call.pt_provider_duration"
                              number placeholder="0" required
                              type="number"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Clinical">
          <b-row>
            <b-col cols="6">
              <b-col v-show="false" cols="12">
                <b-form-group id="group-client-dpt.institution_configs.automatic_form_mapping"
                              description=""
                              label="Automatic form mapping"
                              label-for="selectbox-client-dpt.institution_configs.automatic_form_mapping">
                  <b-form-select v-model="client.configuration.dpt.institution_configs.automatic_form_mapping"
                                 :options="automaticFormMappingOptions"></b-form-select>
                </b-form-group>
              </b-col>
              <div v-if='($isSudo && !client.configuration.dpt.toggles.bypass_clinical_referral_rules)'>
                <b-col cols="12">
                  <b-form-group id="group-client-configuration-dpt-referral_option" description=""
                                label="Referral Provider"
                                label-for="selectbox-client-configuration-dpt-referral_option">
                    <b-col cols="6">
                      <label class='row'>
                        Wheel % distribution
                        <b-form-input v-model="client.configuration.dpt.wheel_balance" max="100" min="0" number
                                      type="number">
                        </b-form-input>
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <label class="row mt-2">
                        OpenLoop % distribution
                        <b-form-input v-model="client.configuration.dpt.openloop_balance" max="100" min="0" number
                                      type="number">
                        </b-form-input>
                        <b-alert v-if='checkSum' class='mt-2' show variant="warning">The sum of the referrals must be
                          100%
                        </b-alert>
                        <b-alert v-if='checkReferrals' class='mt-2' show variant="warning">The referrals must be
                          integers
                        </b-alert>
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <label class='row mt-2'>
                        Send minors to
                        <b-form-select v-model="client.configuration.dpt.provider_minors_referral"
                                       :options="referralsMinorsOptions">
                        </b-form-select>
                      </label>
                    </b-col>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.toggles.show_how_long_experiencing_pain_field"
                                 v-model="client.configuration.dpt.toggles.show_how_long_experiencing_pain_field"
                                 name="checkbox-client-dpt.toggles.show_how_long_experiencing_pain_field"
                                 sync/>
                  Show how long experiencing pain field
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                         title="This question will be displayed on the enrollment questionnaire"
                         variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.toggles.bypass_clinical_referral_rules"
                                 v-model="client.configuration.dpt.toggles.bypass_clinical_referral_rules"
                                 name="checkbox-client-dpt.toggles.bypass_clinical_referral_rules"
                                 sync/>
                  Bypass clinical referral rules
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                         title="Medical referrals will not be requested when enabled"
                         variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.toggles.bypass_pt_regulation_status"
                                 v-model="client.configuration.dpt.toggles.bypass_pt_regulation_status"
                                 name="checkbox-client-dpt.toggles.bypass_pt_regulation_status"
                                 sync/>
                  Bypass PT regulation status
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                         title="Geo-matching between member and PT will not be done"
                         variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.institution_configs.automatic_reassessments"
                                 v-model="client.configuration.dpt.institution_configs.automatic_reassessments"
                                 :value="false"
                                 name="checkbox-client-dpt.institution_configs.automatic_reassessments"
                                 sync/>
                  Automatic reassessments
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                         title="When disabled reassessments to members will not be triggered"
                         variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col v-show="false" cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.institution_configs.disable_prescription_justification"
                                 v-model="client.configuration.dpt.institution_configs.disable_prescription_justification"
                                 :value="false"
                                 name="checkbox-client-dpt.institution_configs.disable_prescription_justification"
                                 sync/>
                  Disable Prescription Justification
                </label>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Kit type">
                  <b-form-select v-model="client.configuration.dpt.kit_type" :options="kitTypeOptions"></b-form-select>
                </b-form-group>
                <b-alert v-if='!client.configuration.dpt.kit_type || client.configuration.dpt.kit_type === ""'
                         class='mt-2' show variant="warning">
                  Kit type is required.
                </b-alert>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-dpt.institution_configs.gad_7" label="GAD-7 > x:"
                              label-for="input-client-dpt.institution_configs.gad_7">
                  <b-form-input id="input-client-dpt.institution_configs.gad_7"
                                v-model="client.configuration.dpt.institution_configs.gad_7"
                                type="number" placeholder="Enter GAD-7">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-dpt.institution_configs.phq_9" label="PHQ-9 > x:"
                              label-for="input-client-dpt.institution_configs.phq_9">
                  <b-form-input id="input-client-dpt.institution_configs.phq_9"
                                v-model="client.configuration.dpt.institution_configs.phq_9"
                                type="number" placeholder="Enter PHQ-9">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-dpt.institution_configs.hurting_thoughts" label="Hurting Thoughts Question:"
                              label-for="input-client-dpt.institution_configs.hurting_thoughts">
                  <b-form-radio v-model="client.configuration.dpt.institution_configs.hurting_thoughts" :value="true"
                                name="dpt.institution_configs.hurting_thoughts-radios">Yes
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.institution_configs.hurting_thoughts" :value="false"
                                name="dpt.institution_configs.hurting_thoughts-radios">No
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Conditions">
          <b-table :fields="conditionsTableFields" v-if="editing"
                   :items="client.configuration.dpt.conditionsMapping"
                   responsive="sm" small striped>
            <template #cell(enabled)="data">
              <toggle-button v-model="data.item.enabled" sync/>
            </template>
            <template #cell(main_form)="data">
              <b-form-input v-model.trim="data.item.main_form" :disabled="!$isSudo"></b-form-input>
            </template>
            <template #cell(complementary_form)="data">
              <b-form-input v-model.trim="data.item.complementary_form" :disabled="!$isSudo"></b-form-input>
            </template>
          </b-table>
          <div v-else>
            <p>Conditions are not editable when creating a client.</p>
            <p>The client will be created with the default conditions enabled.</p>
            <p>Save the client before configuring the conditions.</p>
          </div>
        </b-tab>

        <b-tab title="High risk">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.high_risk.enabled"
                                   v-model="client.configuration.dpt.high_risk.enabled"
                                   name="checkbox-client-dpt.high_risk.enabled" sync/>
                    Is high-risk enabled
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.high_risk.allow_phone_call"
                                   v-model="client.configuration.dpt.high_risk.allow_phone_call"
                                   :disabled="highRiskDisabled"
                                   name="checkbox-client-dpt.high_risk.allow_phone_call" sync/>
                    Allow phone call
                  </label>
                </b-col>

                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.high_risk.toggles.can_skip_video_call"
                                   v-model="client.configuration.dpt.high_risk.can_skip_video_call"
                                   :disabled="highRiskDisabled"
                                   name="checkbox-client-dpt.high_risk.toggles.can_skip_video_call" sync/>
                    Can skip call
                  </label>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-col cols="12">
                <b-form-group id="group-client-dpt.high_risk.marketing_launch_date" description=""
                              label="Marketing launch date:"
                              label-for="input-client-dpt.high_risk.marketing_launch_date">
                  <b-datepicker id="input-client-dpt.high_risk.marketing_launch_date"
                                v-model="client.configuration.dpt.high_risk.marketing_launch_date"/>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-dpt.high_risk.salesforce_account_id" description=""
                              label="SalesForce program_id:"
                              label-for="input-client-dpt.high_risk.salesforce_account_id">
                  <b-form-input id="input-client-dpt.high_risk.salesforce_account_id"
                                v-model.trim="client.configuration.dpt.high_risk.salesforce_account_id" required
                                maxlength='18'
                                type="text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-dpt.high_risk.video_call.duration" label="Call duration:"
                              label-for="input-client-dpt.high_risk.video_call_duration">
                  <b-form-input id="input-client-dpt.high_risk.video_call_duration"
                                v-model="client.configuration.dpt.high_risk.video_call_duration"
                                :disabled="highRiskDisabled" number placeholder="30" required
                                type="number"></b-form-input>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Marketing">
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt.marketing_preferences.eligibility_statement_toggle"
                                v-model="client.configuration.dpt.marketing_preferences.eligibility_statement_toggle"
                                name="checkbox-client-dpt.marketing_preferences.eligibility_statement_toggle" sync/>
                  Eligibility statement
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                   Eligibility Statement - Disabled if toggle is off on the previous field
                  <b-textarea
                    v-model="client.configuration.dpt.marketing_preferences.eligibility_statement"
                    :disabled="!client.configuration.dpt.marketing_preferences.eligibility_statement_toggle"
                    class="mt-4" size="lg"
                    rows="5"/>
                </label>
              </b-col>
              <b-col cols="12">
                <b-form-group id="radiobutton-client-dpt.marketing_preferences.marketing_access" label="Marketing Access:">
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'very high'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">Very high
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'high'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">High
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'medium'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">Medium
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'low'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'very low'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">Very Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'not standard'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">Not Standard
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt.marketing_preferences.marketing_access" :value="'no access'"
                                name="radiobutton-client-dpt.marketing_preferences.marketing_access">No Access
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Channel Opt-in">
                  <b-form-checkbox-group id="checkbox-client-dpt.marketing_preferences.channel_opt_in"
                                          v-model="client.configuration.dpt.marketing_preferences.channel_opt_in"
                                          :options="channelOptInOptions" name="checkbox-client-dpt.marketing_preferences.channel_opt_in"
                                          stacked></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="6">
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.disclaimer_toggle"
                                  v-model="client.configuration.dpt.marketing_preferences.disclaimer_toggle"
                                  name="checkbox-client-dpt.marketing_preferences.disclaimer_toggle" sync/>
                    Disclaimer
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    Disclaimer - Disabled if toggle is off on the previous field
                    <b-textarea
                      v-model="client.configuration.dpt.marketing_preferences.disclaimer"
                      :disabled="!client.configuration.dpt.marketing_preferences.disclaimer_toggle"
                      class="mt-4" size="lg"
                      rows="5"/>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.allow_thematic_campaigns"
                                  v-model="client.configuration.dpt.marketing_preferences.allow_thematic_campaigns"
                                  name="checkbox-client-dpt.marketing_preferences.allow_thematic_campaigns" sync/>
                    Allow thematic campaingns
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.allow_free_language"
                                  v-model="client.configuration.dpt.marketing_preferences.allow_free_language"
                                  name="checkbox-client-dpt.marketing_preferences.allow_free_language" sync/>
                    Allow free language
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.requires_content_changes_approval"
                                  v-model="client.configuration.dpt.marketing_preferences.requires_content_changes_approval"
                                  name="checkbox-client-dpt.marketing_preferences.requires_content_changes_approval" sync/>
                    Requires content changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.requires_aesthetic_changes_approval"
                                  v-model="client.configuration.dpt.marketing_preferences.requires_aesthetic_changes_approval"
                                  name="checkbox-client-dpt.marketing_preferences.requires_aesthetic_changes_approval" sync/>
                    Requires aesthetic changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.sms_allowed"
                                  v-model="client.configuration.dpt.marketing_preferences.sms_allowed"
                                  name="checkbox-client-dpt.marketing_preferences.sms_allowed" sync/>
                    SMS allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.marketing_preferences.emails_allowed"
                                  v-model="client.configuration.dpt.marketing_preferences.emails_allowed"
                                  name="checkbox-client-dpt.marketing_preferences.emails_allowed" sync/>
                    Emails allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.marketing_preferences.member_reference_name"
                                label="Member reference name:"
                                label-for="input-client-dpt.marketing_preferences.member_reference_name">
                    <b-form-input id="input-client-dpt.marketing_preferences.member_reference_name"
                                  v-model.trim="client.configuration.dpt.marketing_preferences.member_reference_name"
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.marketing_preferences.date_of_first_communication"
                                label="Date of first communication sent:"
                                label-for="input-client-dpt.marketing_preferences.date_of_first_communication">
                    <b-form-datepicker id="input-client-dpt.marketing_preferences.date_of_first_communication"
                                  v-model="client.configuration.dpt.marketing_preferences.date_of_first_communication"/>
                  </b-form-group>
                </b-col>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
