<script>

import { required } from 'vuelidate/lib/validators';
import {
  eligibilityCriteriaOptions, implementationStatusOptions, benefitsEnrolledEligibleOptions, memberPaymentOptions, channelOptInOptions,
} from '@/scripts/definitions';

const indexForThisTab = 11;

export default {
  name: 'TabDPTGo',
  props: {
    tab: Object,
    client: Object,
    injuryAreaList: Array,
    editing: Boolean,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      automaticFormMappingOptions: [ { value: 'general', text: 'General' }, { value: 'humana', text: 'Humana' } ],
      eligibilityCriteriaOptions,
      implementationStatusOptions,
      benefitsEnrolledEligibleOptions,
      memberPaymentOptions,
      channelOptInOptions,
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    conditionsTableFields() {
      return [
        { key: 'label', label: 'Condition' },
        { key: 'enabled', label: 'Enabled' },
      ];
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    const isActive = this.client.configuration.dpt_go.enabled;

    if (!isActive) {
      return {};
    }

    return {
      client: {
        configuration: {
          dpt_go: {
            enabled: { required },
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">Atlas</span>
      <span v-else class="text-success">Atlas</span>
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">

        <b-tab title="General" active>
          <b-row>
            <b-col cols="6">
              <b-form-group id="group-client-dpt-go.enabled" label="Is DPT Go enabled:"
                label-for="input-client-dpt-go.enabled">
                <b-form-radio v-model="client.configuration.dpt_go.enabled" name="dpt-go.enabled-radios"
                  :value="true">Enabled</b-form-radio>
                <b-form-radio v-model="client.configuration.dpt_go.enabled" name="dpt-go.enabled-radios"
                  :value="false">Disabled</b-form-radio>
              </b-form-group>

              <b-form-group id="group-client-dpt-go-support_email" label="Support Email:"
                label-for="input-client-dpt-go-support_email" description="">
                <b-form-input id="input-client-dpt-go-support_email" v-model.trim="client.configuration.dpt_go.support_email"
                  type="text" placeholder="" required></b-form-input>
              </b-form-group>

              <b-form-group id="group-client-dpt-go-implementation-status" description=""
                              label="Implementation Status:" label-for="input-client-dpt-go-implementation-status">
                <b-form-select v-model="client.configuration.dpt_go.implementation_status"
                    :options="implementationStatusOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-atlas-eligibility-criteria" description=""
                            label="Eligibility Criteria:" label-for="input-client-atlas-eligibility-criteria">
                <b-form-select v-model="client.configuration.dpt_go.eligibility_criteria"
                  :options="eligibilityCriteriaOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-atlas-benefits-enrolled-eligible" description=""
                            label="Benefits Enrolled/Eligible:" label-for="input-client-atlas-benefits-enrolled-eligible">
                <b-form-select v-model="client.configuration.dpt_go.benefits_enrolled_eligible"
                  :options="benefitsEnrolledEligibleOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-atlas-member-payment" description=""
                            label="Member Payment:" label-for="input-client-atlas-member-payment">
                <b-form-select v-model="client.configuration.dpt_go.member_payment"
                  :options="memberPaymentOptions">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Member app">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button :value="false" sync id="checkbox-client-dpt-go.institution_configs.sword_go_enabled"
                  v-model="client.configuration.dpt_go.institution_configs.sword_go_enabled"
                  name="checkbox-client-dpt-go.institution_configs.sword_go_enabled" :disabled="true" />
                Allow GO Sessions
              </label>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-dpt-go.academy_enabled"
                  v-model="client.configuration.dpt_go.academy_enabled" name="checkbox-client-dpt_go.academy_enabled" />
                Academy enabled
              </label>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-dpt-go.on_call_chat"
                  v-model="client.configuration.dpt_go.on_call_chat" name="checkbox-client-dpt_go.on_call_chat" />
                On call chat enabled
              </label>
              <b-col cols="6">
                <b-form-group id="group-client-dpt_go.on_call_technical_launch_date" description=""
                  label="On-call technical launch date:" label-for="input-client-dpt_go.on_call_technical_launch_date">
                  <b-datepicker id="input-client-dpt_go.on_call_technical_launch_date"
                    v-model="client.configuration.dpt_go.on_call_technical_launch_date"/>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-dpt-go.cbt_program"
                  v-model="client.configuration.dpt_go.cbt_program" name="checkbox-client-dpt-go.cbt_program" />
                CBT Program
              </label>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Conditions">
          <b-table small striped v-if="editing"
                   :fields="conditionsTableFields" :items="client.configuration.dpt_go.conditionsMapping"
            responsive="sm">
            <template #cell(enabled)="data">
              <toggle-button sync v-model="data.item.enabled" />
            </template>
          </b-table>
          <div v-else>
            <p>Conditions are not editable when creating a client.</p>
            <p>The client will be created with the default conditions enabled.</p>
            <p>Save the client before configuring the conditions.</p>
          </div>
        </b-tab>

        <b-tab title="Marketing">
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-dpt_go.marketing_preferences.eligibility_statement_toggle"
                                v-model="client.configuration.dpt_go.marketing_preferences.eligibility_statement_toggle"
                                name="checkbox-client-dpt_go.marketing_preferences.eligibility_statement_toggle" sync/>
                  Eligibility statement
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                   Eligibility Statement - Disabled if toggle is off on the previous field
                  <b-textarea
                    v-model="client.configuration.dpt_go.marketing_preferences.eligibility_statement"
                    :disabled="!client.configuration.dpt_go.marketing_preferences.eligibility_statement_toggle"
                    class="mt-4" size="lg"
                    rows="5"/>
                </label>
              </b-col>
              <b-col cols="12">
                <b-form-group id="radiobutton-client-dpt_go.marketing_preferences.marketing_access" label="Marketing Access:">
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'very high'"
                                name="dpt_go.enabled-radios">Very high
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'high'"
                                name="dpt_go.enabled-radios">High
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'medium'"
                                name="dpt_go.enabled-radios">Medium
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'low'"
                                name="dpt_go.enabled-radios">Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'very low'"
                                name="dpt_go.enabled-radios">Very Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'not standard'"
                                name="dpt_go.enabled-radios">Not Standard
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.dpt_go.marketing_preferences.marketing_access" :value="'no access'"
                                name="dpt_go.enabled-radios">No Access
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Channel Opt-in">
                  <b-form-checkbox-group id="checkbox-client-dpt_go.marketing_preferences.channel_opt_in"
                                          v-model="client.configuration.dpt_go.marketing_preferences.channel_opt_in"
                                          :options="channelOptInOptions" name="checkbox-client-dpt_go.marketing_preferences.channel_opt_in"
                                          stacked></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="6">
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.disclaimer_toggle"
                                  v-model="client.configuration.dpt_go.marketing_preferences.disclaimer_toggle"
                                  name="checkbox-client-dpt_go.marketing_preferences.disclaimer_toggle" sync/>
                    Disclaimer
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    Disclaimer - Disabled if toggle is off on the previous field
                    <b-textarea
                      v-model="client.configuration.dpt_go.marketing_preferences.disclaimer"
                      :disabled="!client.configuration.dpt_go.marketing_preferences.disclaimer_toggle"
                      class="mt-4" size="lg"
                      rows="5"/>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.allow_thematic_campaigns"
                                  v-model="client.configuration.dpt_go.marketing_preferences.allow_thematic_campaigns"
                                  name="checkbox-client-dpt_go.marketing_preferences.allow_thematic_campaigns" sync/>
                    Allow thematic campaingns
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.allow_free_language"
                                  v-model="client.configuration.dpt_go.marketing_preferences.allow_free_language"
                                  name="checkbox-client-dpt_go.marketing_preferences.allow_free_language" sync/>
                    Allow free language
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.requires_content_changes_approval"
                                  v-model="client.configuration.dpt_go.marketing_preferences.requires_content_changes_approval"
                                  name="checkbox-client-dpt_go.marketing_preferences.requires_content_changes_approval" sync/>
                    Requires content changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.requires_aesthetic_changes_approval"
                                  v-model="client.configuration.dpt_go.marketing_preferences.requires_aesthetic_changes_approval"
                                  name="checkbox-client-dpt_go.marketing_preferences.requires_aesthetic_changes_approval" sync/>
                    Requires aesthetic changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.sms_allowed"
                                  v-model="client.configuration.dpt_go.marketing_preferences.sms_allowed"
                                  name="checkbox-client-dpt_go.marketing_preferences.sms_allowed" sync/>
                    SMS allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt_go.marketing_preferences.emails_allowed"
                                  v-model="client.configuration.dpt_go.marketing_preferences.emails_allowed"
                                  name="checkbox-client-dpt_go.marketing_preferences.emails_allowed" sync/>
                    Emails allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt_go.marketing_preferences.member_reference_name"
                                label="Member reference name:"
                                label-for="input-client-dpt_go.marketing_preferences.member_reference_name">
                    <b-form-input id="input-client-dpt_go.marketing_preferences.member_reference_name"
                                  v-model.trim="client.configuration.dpt_go.marketing_preferences.member_reference_name"
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt_go.marketing_preferences.date_of_first_communication"
                                label="Date of first communication sent:"
                                label-for="input-client-dpt_go.marketing_preferences.date_of_first_communication">
                    <b-form-datepicker id="input-client-dpt_go.marketing_preferences.date_of_first_communication"
                                  v-model.trim="client.configuration.dpt_go.marketing_preferences.date_of_first_communication"/>
                  </b-form-group>
                </b-col>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
