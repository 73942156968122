<script>
import { minValue, requiredIf } from 'vuelidate/lib/validators';
import {
  eligibilityCriteriaOptions, implementationStatusOptions, benefitsEnrolledEligibleOptions, memberPaymentOptions, channelOptInOptions,
} from '@/scripts/definitions';


const indexForThisTab = 8;

export default {
  name: 'TabBloom',
  props: {
    tab: Object,
    client: Object,
    injuryAreaList: Array,
    editing: Boolean,
    credentialGroupsList: Array,
    currentTab: { type: Number, required: true },
  },
  data() {
    return {
      eligibilityCriteriaOptions,
      implementationStatusOptions,
      benefitsEnrolledEligibleOptions,
      memberPaymentOptions,
      channelOptInOptions,
      referralsMinorsOptions: [
        {
          value: 'OPENLOOP',
          text: 'Openloop',
        },
        {
          value: 'WHEEL',
          text: 'Wheel',
        },
      ],
      serviceTypesList: [
        {
          text: 'PT provider',
          value: 'pt_provider',
        },
        {
          text: 'Wellness',
          value: 'wellness',
        },
      ],
      currentDate: new Date(),
    };
  },
  computed: {
    tabIsActive() {
      return this.currentTab === indexForThisTab;
    },
    checkReferrals() {
      const wheelBalance = this.client.configuration.bloom.wheel_balance;
      const openLoopBalance = this.client.configuration.bloom.openloop_balance;

      return !Number.isInteger(wheelBalance) || !Number.isInteger(openLoopBalance);
    },
    checkSum() {
      let wheelBalance = -1;
      if (typeof this.client.configuration.bloom?.wheel_balance !== 'undefined' && this.client.configuration.bloom?.wheel_balance != null) {
        wheelBalance = this.client.configuration.bloom?.wheel_balance;
      }
      let openLoopBalance = -1;
      if (typeof this.client.configuration.bloom?.openloop_balance !== 'undefined' && this.client.configuration.bloom?.openloop_balance != null) {
        openLoopBalance = this.client.configuration.bloom?.openloop_balance;
      }

      return (wheelBalance + openLoopBalance) !== 100;
    },
    conditionsTableFields() {
      if (this.editing) {
        return [
          { key: 'label', label: 'Condition' },
          { key: 'enabled', label: 'Enabled' },
          { key: 'main_form', label: 'Main form' },
          { key: 'complementary_form', label: 'Complementary form' },
        ];
      }
      return [
        { key: 'label', label: 'Condition' },
        { key: 'enabled', label: 'Enabled' },
      ];
    },
    tabIcon() {
      if (this.$v.$invalid) {
        return 'alert-triangle';
      }
      return 'check';
    },
  },
  watch: {
    $v: {
      handler(value) {
        this.tab.isValid = !value.$invalid;
      },
      deep: true,
      immediate: true,
    },
    'client.configuration.bloom.toggles.finish_members_program': {
      handler(newValue) {
        if (!newValue) {
          this.client.configuration.bloom.finish_members_program.scheduled_start_date = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations() {
    const isActive = this.client.configuration.bloom.enabled;
    if (!isActive) {
      return {};
    }

    return {
      client: {
        configuration: {
          bloom: {
            credential_group_id: {
              req: requiredIf(elem => elem.credentialRequired === true),
              minValue: minValue(1),
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <b-tab title-item-class="mt-1">
    <template #title>
      <span v-if="tabIsActive">Bloom</span>
      <span v-else class="text-success">Bloom</span>
      <feather class="float-right" :type="tabIcon"></feather>
    </template>
    <b-card-text>
      <b-tabs content-class="mt-3">

        <b-tab title="General" active>
          <b-row>
            <b-col cols="6">
              <b-form-group id="group-client-bloom.enabled" label="Is Bloom enabled:"
                label-for="input-client-bloom.enabled">
                <b-form-radio v-model="client.configuration.bloom.enabled" name="bloom.enabled-radios"
                  :value="true">Enabled</b-form-radio>
                <b-form-radio v-model="client.configuration.bloom.enabled" name="bloom.enabled-radios"
                  :value="false">Disabled</b-form-radio>
              </b-form-group>

              <b-form-group label="Service types:">
                <b-form-checkbox-group
                  id="checkbox-client-bloom.service_types"
                  v-model="client.configuration.bloom.service_types"
                  :options="serviceTypesList"
                  name="checkbox-client-bloom.service_types"
                  stacked>
                </b-form-checkbox-group>
              </b-form-group>

              <b-form-group id="group-client-bloom-support_email" label="Support Email:"
                label-for="input-client-bloom-support_email" description="">
                <b-form-input id="input-client-bloom-support_email" v-model.trim="client.configuration.bloom.support_email"
                  type="text" placeholder="" required></b-form-input>
              </b-form-group>
              <b-form-group id="group-client-bloom-implementation-status" description=""
                              label="Implementation Status:" label-for="input-client-bloom-implementation-status">
                <b-form-select v-model="client.configuration.bloom.implementation_status"
                    :options="implementationStatusOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-bloom-eligibility-criteria" description=""
                            label="Eligibility Criteria:" label-for="input-client-bloom-eligibility-criteria">
                <b-form-select v-model="client.configuration.bloom.eligibility_criteria"
                  :options="eligibilityCriteriaOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-bloom-benefits-enrolled-eligible" description=""
                            label="Benefits Enrolled/Eligible:" label-for="input-client-bloom-benefits-enrolled-eligible">
                <b-form-select v-model="client.configuration.bloom.benefits_enrolled_eligible"
                  :options="benefitsEnrolledEligibleOptions">
                </b-form-select>
              </b-form-group>
              <b-form-group id="group-client-bloom-member-payment" description=""
                            label="Member Payment:" label-for="input-client-bloom-member-payment">
                <b-form-select v-model="client.configuration.bloom.member_payment"
                  :options="memberPaymentOptions">
                </b-form-select>
              </b-form-group>

              <b-form-group id="group-client-bloom.credentialRequired"
                            label="Assigned PT is required institution-specific credential?"
                            label-for="input-client-bloom.credentialRequired">
                <b-form-radio v-model="client.configuration.bloom.credentialRequired" :value="true"
                              name="bloom.credentialRequired-radios">Yes
                </b-form-radio>
                <b-form-radio v-model="client.configuration.bloom.credentialRequired" :value="false"
                              name="bloom.credentialRequired-radios">No
                </b-form-radio>
              </b-form-group>

              <div v-if="client.configuration.bloom.credentialRequired">
                <b-form-group id="group-client-configuration-bloom-credential_group_id" description=""
                              label="Credential group:" label-for="input-client-configuration-bloom-credential_group_id">
                  <b-form-select v-model="client.configuration.bloom.credential_group_id"
                                 :options="credentialGroupsList"></b-form-select>
                </b-form-group>
                <span :style="{ 'color': 'grey' }" class="ml-2"> * Select a credential group</span>
              </div>
                <div>
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.toggles.finish_members_program"
                      v-model="client.configuration.bloom.toggles.finish_members_program"
                      name="checkbox-client-bloom.toggles.finish_members_program" />
                    Finish members program
                  </label>
                </div>
                <div v-if="client.configuration.bloom.toggles.finish_members_program">
                  <b-form-group id="group-client-bloom.finish_members_program.scheduled_start_date"
                                description=""
                                label="Finish members program scheduled start date:"
                                label-for="input-client-bloom.finish_members_program.scheduled_start_date">
                    <b-datepicker
                      id="input-client-bloom.finish_members_program.scheduled_start_date"
                      v-model="client.configuration.bloom.finish_members_program.scheduled_start_date"
                      :max="this.currentDate"/>
                  </b-form-group>
                </div>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.toggles.kit_shipped_only_after_video_call"
                      v-model="client.configuration.bloom.toggles.kit_shipped_only_after_video_call"
                      name="checkbox-client-bloom.toggles.kit_shipped_only_after_video_call"/>
                      Kit shipped after call (if applicable)
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill variant="primary"
                    title="If this config is on and the member has a call
                    scheduled, the kit will only be shipped after the call occurs">
                    <feather type="info"/>
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.navigator_visible"
                      v-model="client.configuration.bloom.navigator_visible"
                      name="checkbox-client-bloom.navigator_visible" />
                    Show on navigator even if it is not available
                  </label>
                  <b-badge v-b-tooltip.hover class="float-right" pill
                          title="Unit card will be displayed in the Navigator even if not enabled for the client"
                          variant="primary">
                    <feather type="info"/>
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.toggles.allow_minor_enrollments"
                      v-model="client.configuration.bloom.toggles.allow_minor_enrollments"
                      name="checkbox-client-bloom.toggles.allow_minor_enrollments" />
                    Allow enrollments by minors
                  </label>
                </b-col>
                <b-col cols="12" v-if="$isSudo">
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.force_eco_experience"
                      v-model="client.configuration.bloom.force_eco_experience"
                      name="checkbox-client-bloom.force_eco_experience" />
                    Force ECO Experience
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.capacity" label="Capacity:"
                    label-for="input-client-bloom.capacity">
                    <template #label>
                      Enrollment Cap:
                      <b-badge variant="primary" pill class="float-right" v-b-tooltip.hover
                        title="Use zero for endless capacity.">
                        <feather type="info" />
                      </b-badge>
                    </template>
                    <b-form-input number id="input-client-bloom.capacity" v-model="client.configuration.bloom.capacity"
                      type="number" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="$isSudo">
                  <b-form-group id="group-client-bloom.redirect_url" label="Redirect url:"
                    label-for="input-client-bloom.redirect_url">
                    <b-form-input number id="input-client-bloom.redirect_url"
                      v-model="client.configuration.bloom.redirect_url" type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.salesforce_account_id" label="SalesForce program_id:"
                    label-for="input-client-bloom.salesforce_account_id" description="">
                    <b-form-input id="input-client-bloom.salesforce_account_id" maxlength='18'
                      v-model.trim="client.configuration.bloom.salesforce_account_id" type="text" required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.technical_launch_date" label="Technical launch date:"
                    label-for="input-client-bloom.technical_launch_date" description="">
                    <b-datepicker id="input-client-bloom.technical_launch_date" v-model="client.configuration.bloom.technical_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.marketing_launch_date" label="Marketing launch date:"
                    label-for="input-client-bloom.marketing_launch_date" description="">
                    <b-datepicker id="input-client-bloom.marketing_launch_date" v-model="client.configuration.bloom.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.unique_addressable_lives" label="Unique Addressable Lives:"
                    label-for="input-client-bloom.unique_addressable_lives">
                    <b-form-input number id="input-client-bloom.unique_addressable_lives"
                      v-model="client.configuration.bloom.unique_addressable_lives" type="number"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Loki">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-dpt.toggles.is_loki_enabled"
                                   v-model="client.configuration.bloom.toggles.is_loki_enabled"
                                   name="checkbox-client-dpt.toggles.is_loki_enabled"
                                   sync/>
                    Enable Loki Phase One
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-dpt.loki.phase_one_enrollment_percentage" description=""
                                label="Loki Phase One Enrollment %" label-for="input-client-dpt.loki.phase_one_enrollment_percentage">
                    <b-form-input id="input-client-dpt.loki.phase_one_enrollment_percentage"
                                  v-model="client.configuration.bloom.loki.phase_one_enrollment_percentage" number
                                  type="number"
                                  min="0" max="100"
                                  required></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Member app">
          <b-row>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-bloom.academy_enabled"
                  v-model="client.configuration.bloom.academy_enabled" name="checkbox-client-bloom.academy_enabled" />
                Academy enabled
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled it will show academy even if the member is actively enrolled in a program"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-bloom.on_call_chat"
                  v-model="client.configuration.bloom.on_call_chat" name="checkbox-client-bloom.on_call_chat" />
                On call chat enabled
              </label>
              <b-col cols="6">
                <b-form-group cols="6" id="group-client-bloom.on_call_technical_launch_date" description=""
                  label="On-call technical launch date:" label-for="input-client-bloom.on_call_technical_launch_date">
                  <b-datepicker id="input-client-bloom.on_call_technical_launch_date"
                    v-model="client.configuration.bloom.on_call_technical_launch_date"/>
                </b-form-group>
              </b-col>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled it will show On-Call chat available even if the member is actively enrolled in a program"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-bloom.show_on_navigator"
                  v-model="client.configuration.bloom.show_on_navigator" name="checkbox-client-bloom.show_on_navigator" />
                Show on navigator/hub
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When enabled the Bloom card will be available on the Hub tab and on the navigator"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-bloom.cbt_program"
                  v-model="client.configuration.bloom.cbt_program" name="checkbox-client-bloom.cbt_program" />
                CBT Program
              </label>
              <b-badge v-b-tooltip.hover class="float-right" pill
                      title="This toggle enables the CBT program for members enrolled in Bloom"
                      variant="primary">
                <feather type="info"/>
              </b-badge>
            </b-col>
            <b-col cols="12">
              <label>
                <toggle-button sync id="checkbox-client-bloom.toggles.show_referrals"
                               v-model="client.configuration.bloom.toggles.show_referrals"
                               name="checkbox-client-bloom.toggles.show_referrals"/>
                  Show referrals
              </label>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Call">
          <b-row>
            <b-col cols="6" v-show="false">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button sync id="checkbox-client-bloom.toggles.enable_sendbird_video_call"
                      v-model="client.configuration.bloom.toggles.enable_sendbird_video_call"
                      name="checkbox-client-bloom.toggles.enable_sendbird_video_call" />
                    Sendbird video calls
                  </label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="4">
              <label>
                <toggle-button id="checkbox-client-bloom.toggles.can_skip_video_call"
                               v-model="client.configuration.bloom.toggles.can_skip_video_call"
                               name="checkbox-client-bloom.toggles.can_skip_video_call"
                               sync/>
                Can skip call
              </label>
              <div>
                <b-form-group id="group-client-bloom.can_skip_video_call_percentage"
                              label="Can skip call %"
                              label-for="input-client-bloom.can_skip_video_call_percentage">
                  <template #label>
                    Can skip call %
                  </template>
                  <b-form-input id="input-client-bloom.can_skip_video_call_percentage"
                                v-model="client.configuration.bloom.can_skip_video_call_percentage"
                                :disabled="!client.configuration.bloom.toggles.can_skip_video_call"
                                max="100" min="1" number
                                placeholder="0" required type="number">
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="6">
              <b-form-group id="group-client-bloom.call_type" description="Use intake flow or treatment flow"
                label="Video call PHS and treatment PHS:" label-for="input-client-bloom.call_type">
                <b-form-radio v-model="client.configuration.bloom.call_type" name="bloom.call_type-radios"
                  value="intake_flow">Can be different (intake flow)
                </b-form-radio>
                <b-form-radio v-model="client.configuration.bloom.call_type" name="bloom.call_type-radios"
                  value="treatment_flow">Should be the same (treatment flow)
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-bloom.number_of_days_to_schedule" label="Number of days to schedule:"
                label-for="input-client-bloom.number_of_days_to_schedule">
                <b-form-input number id="input-client-bloom.number_of_days_to_schedule"
                  v-model="client.configuration.bloom.number_of_days_to_schedule" type="number" placeholder="30"
                  required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group id="group-client-bloom.video_call.buffer" label="Interval between calls:"
                label-for="input-client-bloom.video_call.buffer">
                <b-form-input number id="input-client-bloom.video_call.buffer"
                  v-model="client.configuration.bloom.video_call.buffer" type="number" placeholder="0"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-bloom.video_call.duration" label="Call duration:"
                label-for="input-client-bloom.video_call.duration">
                <b-form-input number id="input-client-bloom.video_call.duration"
                  v-model="client.configuration.bloom.video_call.duration" type="number" placeholder="30"
                  required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group id="group-client-bloom.minimum_scheduling_distance_hours"
                label="First slot available distance in hours:"
                label-for="input-client-bloom.minimum_scheduling_distance_hours">
                <b-form-input number id="input-client-bloom.minimum_scheduling_distance_hours"
                  v-model="client.configuration.bloom.minimum_scheduling_distance_hours" type="number" placeholder="12"
                  required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Clinical">
          <b-row>
            <!-- Referral provider distribution -->
            <b-col cols="6">
              <div v-if='($isSudo && !client.configuration.bloom.toggles.bypass_clinical_referral_rules)'>
                <b-col cols="12">
                  <b-form-group
                    id="group-client-configuration-bloom-referral_option"
                    description=""
                    label="Referral Provider"
                    label-for="selectbox-client-configuration-bloom-referral_option">
                    <b-col cols="6">
                      <label class='row'>
                        Wheel % distribution
                        <b-form-input
                          v-model="client.configuration.bloom.wheel_balance"
                          max="100"
                          min="0"
                          number
                          type="number">
                        </b-form-input>
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <label class="row mt-2">
                        OpenLoop % distribution
                        <b-form-input
                          v-model="client.configuration.bloom.openloop_balance"
                          max="100"
                          min="0"
                          number
                          type="number">
                        </b-form-input>
                        <b-alert v-if='checkSum' class='mt-2' show variant="warning">
                          The sum of the referrals must be 100%
                        </b-alert>
                        <b-alert v-if='checkReferrals' class='mt-2' show variant="warning">
                          The referrals must be integers
                        </b-alert>
                      </label>
                    </b-col>
                    <b-col cols="6">
                      <label class='row mt-2'>
                          Send minors to
                          <b-form-select v-model="client.configuration.bloom.provider_minors_referral" :options="referralsMinorsOptions">
                          </b-form-select>
                      </label>
                    </b-col>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>

            <!--  Bypass clinical referral rules -->
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button
                    id="checkbox-client-bloom.toggles.bypass_clinical_referral_rules"
                    v-model="client.configuration.bloom.toggles.bypass_clinical_referral_rules"
                    name="checkbox-client-bloom.toggles.bypass_clinical_referral_rules"
                    sync/>
                  Bypass clinical referral rules
                </label>
                <b-badge
                  v-b-tooltip.hover
                  class="float-right" pill
                  title="Medical referrals will not be requested when enabled"
                  variant="primary">
                    <feather type="info"/>
                </b-badge>
              </b-col>

              <b-col cols="12">
                <label>
                  <toggle-button sync id="checkbox-client-bloom.institution_configs.automatic_reassessments"
                    v-model="client.configuration.bloom.institution_configs.automatic_reassessments"
                    name="checkbox-clientbloom.institution_configs.automatic_reassessments" />
                  Automatic reassessments
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                      title="When disabled reassessments to members will not be triggered"
                      variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <label>
                  <toggle-button :value="false" sync id="checkbox-client-bloom.toggles.allow_no_pod_sessions"
                    v-model="client.configuration.bloom.toggles.allow_no_pod_sessions"
                    name="checkbox-client-bloom.toggles.allow_no_pod_sessions" />
                  Allow no-pod sessions
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill
                      title="Disable only if specifically requested by the client"
                      variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>

              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-bloom.toggles.bypass_pt_regulation_status"
                                v-model="client.configuration.bloom.toggles.bypass_pt_regulation_status"
                                name="checkbox-client-bloom.toggles.bypass_pt_regulation_status"
                                sync/>
                  Bypass PT regulation status
                </label>
                <b-badge v-b-tooltip.hover class="float-right" pill title="Geo-matching between member and PT will not be done"
                        variant="primary">
                  <feather type="info"/>
                </b-badge>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-bloom.institution_configs.gad_7" label="GAD-7 > x:"
                              label-for="input-client-bloom.institution_configs.gad_7">
                  <b-form-input id="input-client-bloom.institution_configs..gad_7"
                                v-model="client.configuration.bloom.institution_configs.gad_7"
                                type="number" placeholder="Enter GAD-7">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-bloom.institution_configs.phq_9" label="PHQ-9 > x:"
                              label-for="input-client-bloom.institution_configs.phq_9">
                  <b-form-input id="input-client-bloom.institution_configs.phq_9"
                                v-model="client.configuration.bloom.institution_configs.phq_9"
                                type="number" placeholder="Enter PHQ-9">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group id="group-client-bloom.institution_configs.hurting_thoughts" label="Hurting Thoughts Question:"
                              label-for="input-client-bloom.institution_configs.hurting_thoughts">
                  <b-form-radio v-model="client.configuration.bloom.institution_configs.hurting_thoughts" :value="true"
                                name="bloom.institution_configs.hurting_thoughts-radios">Yes
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.institution_configs.hurting_thoughts" :value="false"
                                name="bloom.institution_configs.hurting_thoughts-radios">No
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Conditions">
          <b-table small striped :fields="conditionsTableFields" v-if="editing"
                   :items="client.configuration.bloom.conditionsMapping" responsive="sm">
            <template #cell(enabled)="data">
              <toggle-button sync v-model="data.item.enabled" />
            </template>
            <template #cell(main_form)="data">
              <b-form-input v-model.trim="data.item.main_form" :disabled="!$isSudo"></b-form-input>
            </template>
            <template #cell(complementary_form)="data">
              <b-form-input v-model.trim="data.item.complementary_form" :disabled="!$isSudo"></b-form-input>
            </template>
          </b-table>
          <div v-else>
            <p>Conditions are not editable when creating a client.</p>
            <p>The client will be created with the default conditions enabled.</p>
            <p>Save the client before configuring the conditions.</p>
          </div>
        </b-tab>

        <b-tab title="High risk">
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.high_risk.enabled"
                                   v-model="client.configuration.bloom.high_risk.enabled"
                                   name="checkbox-client-bloom.high_risk.enabled" sync/>
                    Is high-risk enabled
                  </label>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
            <b-col cols="12">
                  <b-form-group id="group-client-bloom.high_risk.marketing_launch_date" description=""
                                label="Marketing launch date:" label-for="input-client-bloom.high_risk.marketing_launch_date">
                    <b-datepicker id="input-client-bloom.high_risk.marketing_launch_date"
                                  v-model="client.configuration.bloom.high_risk.marketing_launch_date"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.high_risk.salesforce_account_id" description=""
                                label="SalesForce program_id:" label-for="input-client-bloom.high_risk.salesforce_account_id">
                    <b-form-input id="input-client-bloom.high_risk.salesforce_account_id"
                                  v-model.trim="client.configuration.bloom.high_risk.salesforce_account_id" required maxlength='18'
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
          </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Marketing">
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label>
                  <toggle-button id="checkbox-client-bloom.marketing_preferences.eligibility_statement_toggle"
                                v-model="client.configuration.bloom.marketing_preferences.eligibility_statement_toggle"
                                name="checkbox-client-bloom.marketing_preferences.eligibility_statement_toggle" sync/>
                  Eligibility statement
                </label>
              </b-col>
              <b-col cols="12">
                <label>
                  Eligibility Statement - Disabled if toggle is off on the previous field
                  <b-textarea
                    v-model="client.configuration.bloom.marketing_preferences.eligibility_statement"
                    :disabled="!client.configuration.bloom.marketing_preferences.eligibility_statement_toggle"
                    class="mt-4" size="lg"
                    rows="5"/>
                </label>
              </b-col>
              <b-col cols="12">
                <b-form-group id="radiobutton-client-bloom.marketing_preferences.marketing_access" label="Marketing Access:">
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'very high'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">Very high
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'high'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">High
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'medium'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">Medium
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'low'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'very low'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">Very Low
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'not standard'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">Not Standard
                  </b-form-radio>
                  <b-form-radio v-model="client.configuration.bloom.marketing_preferences.marketing_access" :value="'no access'"
                                name="radiobutton-client-bloom.marketing_preferences.marketing_access">No Access
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Channel Opt-in">
                  <b-form-checkbox-group id="checkbox-client-bloom.marketing_preferences.channel_opt_in"
                                          v-model="client.configuration.bloom.marketing_preferences.channel_opt_in"
                                          :options="channelOptInOptions" name="checkbox-client-bloom.marketing_preferences.channel_opt_in"
                                          stacked></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="6">
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.disclaimer_toggle"
                                  v-model="client.configuration.bloom.marketing_preferences.disclaimer_toggle"
                                  name="checkbox-client-bloom.marketing_preferences.disclaimer_toggle" sync/>
                    Disclaimer
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    Disclaimer - Disabled if toggle is off on the previous field
                    <b-textarea
                      v-model="client.configuration.bloom.marketing_preferences.disclaimer"
                      :disabled="!client.configuration.bloom.marketing_preferences.disclaimer_toggle"
                      class="mt-4" size="lg"
                      rows="5"/>
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.allow_thematic_campaigns"
                                  v-model="client.configuration.bloom.marketing_preferences.allow_thematic_campaigns"
                                  name="checkbox-client-bloom.marketing_preferences.allow_thematic_campaigns" sync/>
                    Allow thematic campaingns
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.allow_free_language"
                                  v-model="client.configuration.bloom.marketing_preferences.allow_free_language"
                                  name="checkbox-client-bloom.marketing_preferences.allow_free_language" sync/>
                    Allow free language
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.requires_content_changes_approval"
                                  v-model="client.configuration.bloom.marketing_preferences.requires_content_changes_approval"
                                  name="checkbox-client-bloom.marketing_preferences.requires_content_changes_approval" sync/>
                    Requires content changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.requires_aesthetic_changes_approval"
                                  v-model="client.configuration.bloom.marketing_preferences.requires_aesthetic_changes_approval"
                                  name="checkbox-client-bloom.marketing_preferences.requires_aesthetic_changes_approval" sync/>
                    Requires aesthetic changes approval
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.sms_allowed"
                                  v-model="client.configuration.bloom.marketing_preferences.sms_allowed"
                                  name="checkbox-client-bloom.marketing_preferences.sms_allowed" sync/>
                    SMS allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <label>
                    <toggle-button id="checkbox-client-bloom.marketing_preferences.emails_allowed"
                                  v-model="client.configuration.bloom.marketing_preferences.emails_allowed"
                                  name="checkbox-client-bloom.marketing_preferences.emails_allowed" sync/>
                    Emails allowed
                  </label>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.marketing_preferences.member_reference_name"
                                label="Member reference name:"
                                label-for="input-client-bloom.marketing_preferences.member_reference_name">
                    <b-form-input id="input-client-bloom.marketing_preferences.member_reference_name"
                                  v-model.trim="client.configuration.bloom.marketing_preferences.member_reference_name"
                                  type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group id="group-client-bloom.marketing_preferences.date_of_first_communication"
                                label="Date of first communication sent:"
                                label-for="input-client-bloom.marketing_preferences.date_of_first_communication">
                    <b-form-datepicker id="input-client-bloom.marketing_preferences.date_of_first_communication"
                                  v-model.trim="client.configuration.bloom.marketing_preferences.date_of_first_communication"/>
                  </b-form-group>
                </b-col>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-card-text>
  </b-tab>
</template>
