import store from '@/store';
import * as R from 'ramda';
import {
  UNIT_DPT,
  UNIT_BLOOM,
  UNIT_MOVE,
  UNIT_DPT_GO,
  UNIT_MIND,
} from '@/scripts/constants';

const units = [ UNIT_DPT, UNIT_BLOOM, UNIT_MOVE, UNIT_DPT_GO, UNIT_MIND ];

const toSafeString = str => str.normalize('NFD')
  .replace(/[^\w\s]/gi, '') // remove special chars
  .replace(/[\u0300-\u036f]/g, '') // remove 'acentos'
  .replace(/\s+/g, ' ') // remove 1+ white spaces
  .trim();

const _throwOrLogError = (message, doNotBreak) => {
  if (doNotBreak) {
    console.error(message);
  } else {
    throw new Error(message);
  }
};

const cleanupClientFields = client => {
  if (client.configuration.dpt) {
    if (!client.configuration.dpt.conditions) {
      delete client.configuration.dpt.conditions;
    }
    if (!client.configuration.dpt.onboarding_forms || !client.configuration.dpt.onboarding_forms.injury_areas) {
      delete client.configuration.dpt.onboarding_forms;
    }
  }

  if (client.configuration.bloom) {
    if (!client.configuration.bloom.conditions) {
      delete client.configuration.bloom.conditions;
    }
    if (!client.configuration.bloom.onboarding_forms || !client.configuration.bloom.onboarding_forms.injury_areas) {
      delete client.configuration.bloom.onboarding_forms;
    }

    // force boolean values for bloom on_call_chat
    client.configuration.bloom.on_call_chat = client.configuration.bloom.on_call_chat === 'true' || client.configuration.bloom.on_call_chat === true;
  }

  if (client.configuration.dpt_go && !client.configuration.dpt_go.conditions) {
    delete client.configuration.dpt_go.conditions;
  }

  if (typeof client.configuration.legal_links === 'object') {
    Object.keys(client.configuration.legal_links).forEach(lang => {
      if (!client.configuration.legal_links[lang]) {
        delete client.configuration.legal_links[lang];
      }
    });
  } else {
    delete client.configuration.legal_links;
  }

  return client;
};

function cast(type, input) {
  switch (type) {
    case 'string':
      return String(input);
    case 'number':
    case 'integer':
      return Number(input);
    case 'boolean':
      return [ '1', 1, true, 'true' ].includes(input);
    default:
      return input;
  }
}

const mapInstitutionIntoClient = (client, institution, isRevisionRequest = false) => {
  if (!client.display_name) {
    client.display_name = institution.name;
  }
  if (!client.reference) {
    client.reference = institution.unique_key;
  }

  client.configuration.client_mode = institution.institution_mode;
  client.configuration.institution_timezone = institution.timezone;
  client.configuration.industry_broad = institution.industry_broad;
  client.configuration.industry_sub = institution.industry_sub;
  client.configuration.institution_type = institution.institution_type;
  client.configuration.institution_category = institution.institution_category;
  client.configuration.follows_care_ecosystem_rules = +institution.follows_care_ecosystem_rules;
  client.configuration.general_guidelines = institution.general_guidelines;
  client.institution_id = institution.institutionID;

  institution.ecosystems.forEach(e => {
    const unit = e.unit === 'sword' ? 'dpt' : e.unit;
    client.configuration[unit].credential_group_id = e.credential_group_id;
    client.configuration[unit].credentialRequired = Number(e.credential_group_id) > 0;
  });

  institution.institution_ecosystem_configs.forEach(c => {
    const unit = c.unit === 'sword' ? 'dpt' : c.unit;
    const key = isRevisionRequest ? c.key : c.ecosystem_config.key;
    const value = isRevisionRequest ? c.value : cast(c.ecosystem_config.type, c.value);
    client.configuration[unit].institution_configs[key] = value;
  });

  return client;
};

const getFormsForInjuryArea = (client, injuryArea) => {
  if (injuryArea.isBloom) {
    if (!client.configuration.bloom.onboarding_forms.injury_areas[injuryArea.key]) {
      return {
        onboarding_regular_form_key: null,
        onboarding_complementary_form_key: null,
      };
    }
    return client.configuration.bloom.onboarding_forms.injury_areas[injuryArea.key];
  }

  if (!client.configuration.dpt.onboarding_forms.injury_areas[injuryArea.key]) {
    return {
      onboarding_regular_form_key: null,
      onboarding_complementary_form_key: null,
    };
  }
  return client.configuration.dpt.onboarding_forms.injury_areas[injuryArea.key];
};

const mapInjuryAreas = (client, injuryAreas, editing) => {
  if (!injuryAreas || injuryAreas.length < 1) {
    return client;
  }

  client.configuration.dpt.conditionsMapping = [];
  client.configuration.bloom.conditionsMapping = [];
  client.configuration.dpt_go.conditionsMapping = [];

  const excludedInjuryAreasDPT = [ 'pelvic', 'falls_prevention' ];

  injuryAreas.forEach(injuryArea => {
    const injuryAreaForms = getFormsForInjuryArea(client, injuryArea);
    injuryArea.main_form = injuryAreaForms.onboarding_regular_form_key;
    injuryArea.complementary_form = injuryAreaForms.onboarding_complementary_form_key;

    injuryArea.units.forEach(unit => {
      switch (unit) {
        case 'bloom':
          if (editing) {
            injuryArea.enabled = Object.values(client.configuration.bloom.conditions).includes(injuryArea.key);
          }
          client.configuration.bloom.conditionsMapping.push({ ...injuryArea });
          break;
        case 'dpt':
          if (editing) {
            injuryArea.enabled = Object.values(client.configuration.dpt.conditions).includes(injuryArea.key);
          }

          if (!excludedInjuryAreasDPT.includes(injuryArea.key)) {
            client.configuration.dpt.conditionsMapping.push({ ...injuryArea });
          }
          break;
        case 'dpt_go':
          if (editing) {
            injuryArea.enabled = Object.values(client.configuration.dpt_go.conditions).includes(injuryArea.key);
          }
          if (!excludedInjuryAreasDPT.includes(injuryArea.key)) {
            client.configuration.dpt_go.conditionsMapping.push({ ...injuryArea });
          }
          break;
        default:
      }
    });
  });

  return client;
};

const mapClientDataForSave = (c, editing, ignoreErrors = false) => {
  const client = R.clone(c);
  let i = 0;

  client.configuration.data_level_access = client.data_access;
  delete client.configuration.industry_broad;
  delete client.configuration.industry_sub;
  delete client.configuration.institution_type;
  delete client.configuration.institution_category;
  client.page_title = client.display_name;

  if (!editing) {
    client.configuration.bloom.force_eco_experience = true;
    client.environment = store.getters['Core/getSelectedApi']; // TODO remove when API doesn't need this
    delete client.configuration.dpt.conditions;
    delete client.configuration.dpt.onboarding_forms;
    delete client.configuration.dpt.conditionsMapping;
    delete client.configuration.bloom.conditions;
    delete client.configuration.bloom.onboarding_forms;
    delete client.configuration.bloom.conditionsMapping;
    delete client.configuration.dpt_go.conditions;
    delete client.configuration.dpt_go.conditionsMapping;

    client.configuration.dpt.enabled = false;
    client.configuration.bloom.enabled = false;
    client.configuration.dpt_go.enabled = false;
    client.configuration.move.enabled = false;
    // client.configuration.mind.enabled = false;
    client.configuration.no_program.enabled = true;
    client.configuration.triage_helper.enabled = false;
    client.active = false;

    return client;
  }

  if (client.configuration.dpt.toggles.address_before_condition) {
    const weight = Math.floor(client.configuration.dpt.address_before_condition_percentage);
    if (weight <= 0 || weight > 100) {
      _throwOrLogError('Address before condition percentage must be between 1 and 100', ignoreErrors);
    }
    client.configuration.dpt.address_before_condition_percentage = weight;
  }

  if (client.configuration.dpt.toggles.finish_members_program) {
    if (client.configuration.dpt.finish_members_program.scheduled_start_date == null) {
      const today = new Date().toISOString().split('T')[0];
      client.configuration.dpt.finish_members_program.scheduled_start_date = today;
    }
  }

  if (client.configuration.bloom.toggles.finish_members_program) {
    if (client.configuration.bloom.finish_members_program.scheduled_start_date == null) {
      const today = new Date().toISOString().split('T')[0];
      client.configuration.bloom.finish_members_program.scheduled_start_date = today;
    }
  }

  client.configuration.dpt.onboarding_forms.injury_areas = {};
  client.configuration.dpt.conditions = {};
  if (!client.configuration.dpt.onboarding_forms.synced_at) {
    client.configuration.dpt.onboarding_forms.synced_at = new Date().toISOString();
  }
  client.configuration.dpt.conditionsMapping.forEach(condition => {
    if (!condition.enabled) {
      return;
    }

    client.configuration.dpt.conditions[i] = condition.key;
    i++;
    if (!condition.main_form) {
      return;
    }
    client.configuration.dpt.onboarding_forms.injury_areas[condition.key] = {
      onboarding_regular_form_key: condition.main_form,
      onboarding_complementary_form_key: condition.complementary_form || null,
    };
  });

  if (client.configuration.dpt.enabled && Object.values(client.configuration.dpt.conditions).length < 1) {
    _throwOrLogError('DPT cannot be enabled without conditions', ignoreErrors);
  }
  delete client.configuration.dpt.conditionsMapping;

  client.configuration.bloom.onboarding_forms.injury_areas = {};
  client.configuration.bloom.conditions = {};
  if (!client.configuration.dpt.onboarding_forms.synced_at) {
    client.configuration.dpt.onboarding_forms.synced_at = new Date().toISOString();
  }
  i = 0;
  client.configuration.bloom.conditionsMapping.forEach(condition => {
    if (!condition.enabled) {
      return;
    }

    client.configuration.bloom.conditions[i] = condition.key;
    i++;
    if (!condition.main_form) {
      return;
    }
    client.configuration.bloom.onboarding_forms.injury_areas[condition.key] = {
      onboarding_regular_form_key: condition.main_form,
      onboarding_complementary_form_key: condition.complementary_form,
    };
  });
  if (client.configuration.bloom.toggles.can_skip_video_call) {
    const weight = Math.floor(client.configuration.bloom.can_skip_video_call_percentage);
    if (weight <= 0 || weight > 100) {
      _throwOrLogError('Can skip video call percentage must be between 1 and 100', ignoreErrors);
    }
    client.configuration.bloom.can_skip_video_call_percentage = weight;
  }
  if (client.configuration.bloom.enabled && Object.values(client.configuration.bloom.conditions).length < 1) {
    throw new Error('Bloom cannot be enabled without conditions');
  }
  delete client.configuration.bloom.conditionsMapping;

  i = 0;
  client.configuration.dpt_go.conditions = {};
  client.configuration.dpt_go.conditionsMapping.forEach(condition => {
    if (!condition.enabled) {
      return;
    }

    client.configuration.dpt_go.conditions[i] = condition.key;
    i++;
  });

  if (client.configuration.dpt_go.enabled && Object.values(client.configuration.dpt_go.conditions).length < 1) {
    _throwOrLogError('DPT Go cannot be enabled without conditions', ignoreErrors);
  }
  delete client.configuration.dpt_go.conditionsMapping;

  client.configuration.toggles.show_hub_tab = true;
  client.configuration.dpt.show_hub_tab = true;
  client.configuration.bloom.show_hub_tab = true;
  client.configuration.move.show_hub_tab = true;
  client.configuration.no_program.show_hub_tab = true;
  client.configuration.mind.show_hub_tab = false;

  client.configuration.toggles.required_member_id_field = client.configuration.toggles.show_member_id_field;

  if (client.configuration.dpt.wheel_balance === '') {
    client.configuration.dpt.wheel_balance = 0;
  }

  if (client.configuration.dpt.openloop_balance === '') {
    client.configuration.dpt.openloop_balance = 0;
  }

  if (client.configuration.bloom.wheel_balance === '') {
    client.configuration.bloom.wheel_balance = 0;
  }

  if (client.configuration.bloom.openloop_balance === '') {
    client.configuration.bloom.openloop_balance = 0;
  }

  if (!client.onboarding_flow_uuid) {
    delete client.onboarding_flow_uuid;
  }

  // Global Client can be enabled only when DPT Go is the sole active unit.
  if (client.configuration.toggles.global_client
    && (client.configuration.dpt.enabled || client.configuration.bloom.enabled || client.configuration.move.enabled)) {
    _throwOrLogError('Global Client can be enabled only when DPT Go is the sole active unit', ignoreErrors);
  }

  delete client.logo.selected;
  delete client.logo.available_logos;

  return client;
};

const createInstitutionDataForSave = client => {
  const institution = {
    institutionID: client.institution_id || null,
    name: toSafeString(client.display_name),
    unique_key: client.reference,
    is_test: client.configuration.client_mode === 'TEST',
    timezone: client.configuration.institution_timezone || 'UTC',
    institution_mode: client.configuration.client_mode,
    industry_broad: client.configuration.industry_broad,
    industry_sub: client.configuration.industry_sub,
    institution_type: client.configuration.institution_type,
    institution_category: client.configuration.institution_category,
    data_level_access: client.data_access,
    is_new: true, // check if we can remove this...
    ecosystems: [],
    institution_ecosystem_configs: [],
    follows_care_ecosystem_rules: client.configuration.follows_care_ecosystem_rules,
    general_guidelines: client.configuration.general_guidelines,
    preferred_vendor: client.configuration.preferred_vendor,
  };

  units.forEach(u => {
    const unit = u === 'dpt' ? 'sword' : u;
    // setting ecosystems
    const credentialId = client.configuration[u].credentialRequired ? client.configuration[u].credential_group_id : null;
    institution.ecosystems.push({ unit, active: client.configuration[u].enabled, credential_group_id: credentialId });

    // adding unit institution configs
    Object.entries(client.configuration[u].institution_configs)
      .forEach(([ key, value ]) => {
        institution.institution_ecosystem_configs.push({ unit, key, value });
      });
  });

  return institution;
};

export {
  cleanupClientFields,
  mapInjuryAreas,
  mapClientDataForSave,
  createInstitutionDataForSave,
  mapInstitutionIntoClient,
};
